import * as React from 'react';
import {useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import { blue } from '@mui/material/colors';
import Box,  { BoxProps } from '@mui/material/Box';
import { Container } from '@mui/system';

import FoodCardIcon from "../../assets/img/temp/FoodCardIcon.png";
import RotaryLogo from "../../assets/img/temp/RotaryLogo.png";

import IconTable from "../../assets/img/icons2/tables.png";
import IconForm from "../../assets/img/icons2/forms.png";

import IconWinter from "../../assets/img/icons3/winter.png";
import IconGen from "../../assets/img/icons3/generator.png";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function TechaidPage() {
  const form1 = {
    title: "Форма: Генератори для медзакладів", 
    subtitle: "",
    desc: "", 
    icon: IconForm, 
    icon2: IconGen,
    ref: "https://forms.gle/T8QLU32H7g12gTgL9",
  }
  const form2 = {
    title: "Форма: Генератори для цивільних потреб", 
    subtitle: "",
    desc: "", 
    icon: IconForm, 
    icon2: IconGen,
    ref: "https://forms.gle/JA3RX5FMH71W7ANAA",
  }
  const form3 = {
    title: "Форма: Інші потреби підкомітету Техдопомога", 
    subtitle: "пічки, обігрівачі, ковдри, термобілизна",
    desc: "", 
    icon: IconForm, 
    icon2: IconWinter,
    ref: "https://forms.gle/k7vpWHjnZeKoBGaL8",
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onClickEmpty = () => {
    
  };

  const onClickForm = (ref: string) => {
    window.location.href = ref ;
  };

  useEffect(() => {
    // change background color with a random color
    //const color = Math.floor(Math.random()*16777215).toString(16);
    document.body.style.background = '#f0ebf8';
  });

  return (
    <Box  width="100%" display="flex" flexDirection="column" sx={{pt: '20px'}} >
    <Container >
    <Box  width="100%" sx={{
        maxWidth: '645px',
        pt: '20px', 
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: 2,
        p: 2,
        
    }} >
      <Typography variant="h4" color="darkblue">Форми групи Техдопомога (Зима). </Typography>
      <Typography variant="h6" color="darkblue">Так як заявки на генератори специфічні, для них відведено спеціальні форми. </Typography>
      <Typography variant="h6" color="black">Оберіть підрозділ, та натисніть кнопку нижче</Typography>
      <Card sx={{ maxWidth: '645px', bgcolor: blue[50], m: '20px'}}>
        <CardActionArea onClick={()=>onClickForm(form1.ref)} >
          <CardContent>
            
              <Grid container spacing={3} display="flex" flexDirection="row">
                <Grid item>
                  <img height='30px' src={form1.icon2} />
                </Grid>
                <Grid item>
                  <img height='30px' src={form1.icon} />
                </Grid>
                
              
              </Grid>
              <Grid item>
                  <Typography variant='h6' color={'darkblue'}>{form1.title}</Typography>
                  {form1.subtitle ? <Typography variant='body2'>({form1.subtitle})</Typography> : null}
                  <Typography variant='body1'>{form1.desc}</Typography>
                </Grid>

          </CardContent>
      </CardActionArea>
      </Card>
      <Card sx={{ maxWidth: 645, bgcolor: blue[50], m: '20px'}}>
        <CardActionArea onClick={()=>onClickForm(form2.ref)} >
          <CardContent>
            
              <Grid container spacing={3} display="flex" flexDirection="row">
                <Grid item>
                  <img height='30px' src={form2.icon2} />
                </Grid>
                <Grid item>
                  <img height='30px' src={form2.icon} />
                </Grid>
                
              
              </Grid>
              <Grid item>
                  <Typography variant='h6' color={'darkblue'}>{form2.title}</Typography>
                  {form1.subtitle ? <Typography variant='body2'>({form2.subtitle})</Typography> : null}
                  <Typography variant='body1'>{form2.desc}</Typography>
                </Grid>

          </CardContent>
      </CardActionArea>
      </Card>

      <Card sx={{ maxWidth: 645, bgcolor: blue[50], m: '20px'}}>
        <CardActionArea onClick={()=>onClickForm(form3.ref)} >
          <CardContent>
            
              <Grid container spacing={3} display="flex" flexDirection="row">
                <Grid item>
                  <img height='30px' src={form3.icon2} />
                </Grid>
                <Grid item>
                  <img height='30px' src={form3.icon} />
                </Grid>
                
              
              </Grid>
              <Grid item>
                  <Typography variant='h6' color={'darkblue'}>{form3.title}</Typography>
                  {form3.subtitle ? <Typography variant='body2'>({form3.subtitle})</Typography> : null}
                  <Typography variant='body1'>{form3.desc}</Typography>
                </Grid>

          </CardContent>
      </CardActionArea>
      </Card>
    </Box>
    </Container>
    </Box>
  );
}