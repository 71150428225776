
// const superUserList = [
//      "tykogm7@gmail.com",
//     ]

export interface IUserProfile {
    userName: string | undefined,
    email: string | undefined,
    imgUrl: string | undefined,
    committee: ISuperUserProfile | null,
};

// export const isSuperUser = (email:string | undefined) => {
//     const ret:boolean = email ? superUserList.indexOf(email) !== -1 : false;
//     return ret;
// }

export interface ISuperUserProfile {
    name: string,
    email: string,
    subcom: string,
    desc: string,
};
const tableNameArr:ISuperUserProfile[] = [
    {subcom: 'medical', email: "oncology@2upost.com", desc: "голова медичного підкомітету", name: "Анна Кожина"},
    {subcom: 'logistic', email: "borysbodnar@gmail.com", desc: "голова логістичного підкомітету", name: "Борис Боднар"},
    //{subcom: 'techaid', email: "o.kosmyna.rotary@gmail.com", desc: "голова підкомітету техдопомоги", name: "Олег Косьмина"},
    {subcom: 'specavto', email: "volbondarenko@gmail.com", desc: "голова підкомітету спецтехніки", name: "Володимир Бондаренко"},
    //{subcom: 'wash', email: "mmgitelman@gmail.com", desc: "голова підкомітету Wash", name: "Марк Гітельман"},
    //{subcom: 'people', email: "sergiy.zavadsky@gmail.com", desc: "голова підкомітету Люди", name: "Сергій Завадський"},
    //{subcom: 'other', email: "sniko@ukr.net", desc: "голова підкомітету Інше", name: "Микола Стеблянко"},
    //other KK
    //{subcom: 'coordcom', email: "leskovit@gmail.com", desc: "губернатор дистрикту", name: "Віталій Лесько"},
    //{subcom: 'KK', email: "tykomobile@gmail.com", desc: "тестовий користувач", name: "Тестер Олег"},
    {subcom: 'coordcom', email: "hkroichyk@gmail.com", desc: "член Координиційного комітету", name: "Генадій Кройчик"}, 
    {subcom: 'coordcom', email: "bohd_an@ukr.net", desc: "член Координиційного комітету", name: "Богдан Свідовий"},
    {subcom: 'coordcom', email: "bohdan.svidovyy@gmail.com", desc: "член Координиційного комітету", name: "Богдан Свідовий"},
    {subcom: 'coordcom', email: "i_yakov@ukr.net", desc: "голова Фінкомітету", name: "Ігор Яковчук"},
    //ST - subcommittee SpecTech
    {subcom: 'SK:specavto', email: "lesiadzhadan@gmail.com", desc: "член Підкомітету СпецТехніка", name: "Леся Джадан"},
    {subcom: 'SK:specavto', email: "tykomobile@gmail.com", desc: "SK: член Підкомітету СпецТехніка", name: "SK: Тестер Олег"},
];

export const getTableNameByIndex = (ind:number) => {
    if (ind>=0 && ind<tableNameArr.length)
        return tableNameArr[ind].subcom;
    else 
        return '';
}

export const isAnyUser = (email:string | undefined) => {

    console.log("====== isAnyUser: email="+email);
    let ret:boolean = false;
    tableNameArr.map((item) => {
        if (item.email === email) 
            ret = true;
    })
    
    console.log("====== isAnyUser3: ret="+(ret?"true":"false"));
    return ret;
}

export const isSuperUser = (email:string | undefined) => {
    
    console.log("====== isSuperUser: email="+email);
    let ret:boolean = false;
    tableNameArr.map((item) => {
        if (item.email === email && !item.subcom.startsWith('SK:')) 
            ret = true;
    })
    
    console.log("====== isSuperUser3: ret="+(ret?"true":"false"));
    return ret;
}

export const isSubcommitteeUser = (email:string | undefined, table:string | null) => {
    //if (isSuperUser(email))
    //    return true;
    console.log("====== isSubcommitteeUser1: email="+email+", tabele="+table);
    if (!table || !email)
            return false;
    const tableName = ftableToTable(table)
    if (!tableName)
            return false;
    console.log("====== isSubcommitteeUser2: email="+email+", tabele="+tableName);
    let ret:boolean = false;
    tableNameArr.map((item) => {
        console.log("====== isSubcommitteeUser3_1: email="+email+", item.email="+item.email+", tabele="+tableName+", item.subcom="+item.subcom);
        if (item.email === email && item.subcom === ('SK:'+tableName)) {
            console.log("====== isSubcommitteeUser3: email="+email+", tabele="+tableName+", item.subcom="+item.subcom);
            ret = true;
        }
    })
    console.log("====== isSubcommitteeUser4: ret="+(ret?"true":"false"));
    return ret;
}

const ftableToTable = (t:string) => {
    //const formList = ['forms','fmed', 'flog', 'ftech', 'fspec', 'fwash', 'fpeople', 'fcoordcom'];
    //const formList2 = ['medical', 'logistic', 'techaid', 'specavto', 'wash', 'people', 'other'];
    const formList = ['forms','fmed', 'flog', 'fspec'];
    const formList2 = ['medical', 'logistic', 'specavto'];

    const index = formList.indexOf(t);
    if( index > 0 && index < 7)
        return formList2[index-1];
    else
        return null;
}

export const getSuperUserProfile = (email:string | undefined) => {
    
    let ret:ISuperUserProfile|null = null;
    tableNameArr.map((item) => {
        if (item.email === email) 
            ret = item;
    })
    return ret;
}