import React, {useState} from 'react';

import TableContainer from '@mui/material/TableContainer';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { pink } from '@mui/material/colors';
import {useLocalStorage} from "../useLocalStorage";

import {apiLoadTykoTablesInfoList, apiLoadTykoTableInfoData, apiLoadTykoTableData, ITykoTableListInfo, ITykoTableInfo, ITykoTableData, ITykoRows} from '../TykoTables/api/apiTykoTables';
import GSTykoTableV2 from './GSTykoTableV2';
import TykoTableDetailsPage from '../TykoTables/TykoTableDetailsPage';
import TTHeader from '../TykoTables/TTHeader'
import { async } from 'q';
import GoogleLoginButton from '../GoogleLogin/GoogleLoginButton';
import {IUserProfile} from '../api/apiAdministration';
import ColoredCheckbocksFilter from '../GuiComponents/ColoredCheckbocksFilter';
import {apiGSUpdateCell} from './api/apiGSUpdateCell';

const languageArr = ["Ang", "Укр"];
const defaultLangId = 1;//Ang

export default function TykoTableFrameV2({needEmailFilter, sheetId, userProfile, isDebug}:{needEmailFilter:boolean, sheetId:string, userProfile:IUserProfile|null, isDebug:boolean}) {    
    
    const [langIdSaved, langIdSavedSet] = useLocalStorage<number>('langId', 1);
    const [arrCheckboxStatus, arrCheckboxStatusSet] = useLocalStorage<boolean[] | []>('arrCheckboxStatus', []);

    const [tableMeta, tableMetaSet] = useState<ITykoTableListInfo[] | []>([]);
    const [tableInfo, tableInfoSet] = useState<ITykoTableListInfo | null>(null);
    const [tableInfoData, tableInfoDataSet] = useState<ITykoTableInfo | null>(null);
    const [tableData, tableDataSet] = useState<ITykoTableData[] | []>([]);
    const [filteredTableData, filteredTableDataSet] = useState<ITykoTableData[] | []>([]);
    const [openDetailsDialogIndex, openDetailsDialogIndexSet] = React.useState(-1);
    const [langId, langIdSet] = useState<number>(languageArr && languageArr.length > langIdSaved ? langIdSaved : defaultLangId);
    const [sheetId2, sheetId2Set] = useState<string>("");
    const [pages, pagesSet] = useState<string[]>([]);
    const [checkedGray, setCheckedGray] = useState<boolean>(arrCheckboxStatus && arrCheckboxStatus.length >= 2 ? arrCheckboxStatus[0] : true);
    const [checkedGreen, setCheckedGreen] = useState<boolean>(arrCheckboxStatus && arrCheckboxStatus.length >= 2 ? arrCheckboxStatus[1] : true);
    

    const fetchMyAPI = React.useCallback(async () => {
        const metaList: ITykoTableListInfo[] = await apiLoadTykoTablesInfoList();
        console.log("gs loadMetaData arrsize="+metaList.length+"]");
        tableMetaSet(metaList);

        const correctedSheetIdent = initSheetId(metaList);

        if (metaList)
            loadData(metaList, correctedSheetIdent);
    }, []);

    const loadData = async (list:ITykoTableListInfo[], correctedSheetIdent:string) => {  
        const filteredList: ITykoTableListInfo[] = filterGroupeList(list);
        console.log("=============2 filteredList count="+filteredList.length);
        const pages_:string[] = getSheetList(filteredList);
        pagesSet(pages_);
        
        const info: ITykoTableListInfo | null = getITykoTableInfoFromList(filteredList, correctedSheetIdent);
        tableInfoSet(info);
        console.log("============= info"+(info?"true2":"false2"));

        if (info) {
            const info2: ITykoTableListInfo = info;
            const data: ITykoTableInfo | null = await apiLoadTykoTableInfoData(correctedSheetIdent);
            tableInfoDataSet(data);

            if (data) {
                const tykoData = await apiLoadTykoTableData(info2.tableIndex, info2.sheetIndex, data);
                tykoData.reverse();
                tableDataSet(tykoData);
                
                console.log("============= email="+userProfile?.email);
                //filteredTableDataSet(filterTableData(userProfile?.email, checkedGray, checkedGreen, tykoData));
            }
        }
    }
    const filterTableData = (email:string|undefined, chGray:boolean, chGreen:boolean, data:ITykoTableData[]) => {
        const newdata:ITykoTableData[] = [];
        data.map((item) => {
            if ( ( (chGray && item.color === 'lightgray') ||
            (chGreen && (item.color === 'PaleGreen' || item.color === 'LightBlue')) || 
            (item.color !== 'lightgray' && item.color !== 'PaleGreen' && item.color !== 'LightBlue') )
            && (!needEmailFilter || (needEmailFilter && email === item.email))
            )
                newdata.push(item);
            //console.log('======= filterTableData uemail='+email+', email='+item.email);
        })
        return newdata;
    }
    const clearData = async () => {
        //tableInfoSet(null);
        tableInfoDataSet(null);
        tableDataSet([]);
    }
    
    React.useEffect(() => {
        filteredTableDataSet(filterTableData(userProfile?.email, checkedGray,checkedGreen, tableData))
    }, [userProfile?.email, checkedGray, checkedGreen, tableData, sheetId]);

    React.useEffect(() => {
        console.log("==== useEffect reload all data")
        console.log("swtch page to "+sheetId);
        clearData();
        sheetId2Set(sheetId);
        loadData(tableMeta, sheetId);
    }, [sheetId]);

    React.useEffect(() => {
        fetchMyAPI();
    }, [fetchMyAPI]);

    const initSheetId = (list:ITykoTableListInfo[]) => {
        let correctedSheetIdent:string = "";

        const groupList:string[] = getGroupList(list);
        if (groupList.includes(sheetId)) {
            const reversed = [...list].reverse();
            reversed.map((item) => {
                if (item.group === sheetId) {
                    correctedSheetIdent = item.tableId;
                    console.log("============= initSheetId(group) correctedSheetIdent="+correctedSheetIdent);
                }
            })
        }
        else { //single sheet
            const reversed = [...list].reverse();
            reversed.map((item) => {
                if (item.tableId === sheetId) {
                    correctedSheetIdent = sheetId;
                    console.log("============= initSheetId(single) correctedSheetIdent="+correctedSheetIdent);
                }
            })
        }
        
        return correctedSheetIdent;
    }
    
    const filterGroupeList = (list:ITykoTableListInfo[]) => {
        const strArr:ITykoTableListInfo[] = [];
        const groupList:string[] = getGroupList(list);
        //console.log("============= groupList size="+groupList.length);
        //console.log("======== groupList 1="+groupList[0]);
        //console.log("======== groupList s="+sheetId);
        if (groupList.includes(sheetId)) {
            list.map((item) => {
                if (item.group === sheetId) {
                    strArr.push(item);
                    console.log("============= filterGroupeList(group) item="+item.tableId+", sheetId2="+sheetId2);
                }
            })
        }
        else { //single sheet
            list.map((item) => {
                if (item.tableId === sheetId) {
                    strArr.push(item);
                    //sheetId2Set(sheetId);
                    console.log("============= filterGroupeList2(single) item="+item.tableId+", sheetId2="+sheetId2);
                }
            })
        }
        
        return strArr;
    };
    const getGroupList = (list:ITykoTableListInfo[]) => {
        //console.log("============= getGroupList");
        const strArr:string[] = [];
        list.map((item) => {
            if (!strArr.includes(item.group)) {
                strArr.push(item.group);
                //console.log("============= getGroupList item="+item.group);
            }
        })
        return strArr;
    };
    const getSheetList = (list:ITykoTableListInfo[]) => {
        console.log("============= getSheetList");
        const strArr:string[] = [];
        list.map((item) => {
            //if (!strArr.includes(item.group)) {
                strArr.push(item.tableId);
                console.log("============= getSheetList item="+item.tableId);
            //}
        })
        return strArr;
    };

    const getITykoTableInfoFromList = (list:ITykoTableListInfo[] | [], id:string) => {
        let sheetInfo: ITykoTableListInfo | null = null;
        console.log("getITykoTableInfoFromList size="+list.length);
        list.forEach((item) => {
            console.log("getITykoTableInfoFromList item.tableId="+item.tableId+", seachid="+id);
            if (item.tableId === id) {
                sheetInfo = item;
            }
        })
        return sheetInfo;
    };

    const colTitleList = (list:ITykoRows[]) => {
        const strArr:string[] = [];
        list.map((item) => {
            strArr.push(item.rowText_Lang[langId]);
        })
        return strArr;
    };

    const handleDetilsDialogClose = () => {
        openDetailsDialogIndexSet(-1);
    };

    const rowTableClicked = (index: number) => {
        openDetailsDialogIndexSet(index);
    }

    const onSwitchLanguage = () => {
        console.log("swtch lang to "+languageArr[langId]);
        langIdSet(langId===0 ? 1 : 0);
        langIdSavedSet(langId===0 ? 1 : 0);
    }

    const onSwitchPages = (page:string) => {
        if(page === sheetId2) 
            console.log("swtch ignore. curent page = "+sheetId2);
        else
        {
            console.log("swtch page to "+page);
            clearData();
            sheetId2Set(page);
            loadData(tableMeta, page);
        }
    }

    const handleChangeGray = () => {
        setCheckedGray(!checkedGray);
        arrCheckboxStatusSet([!checkedGray, checkedGreen]);
        console.log("swtch cheked gray to "+!checkedGray);
        if (tableData) {
            filteredTableDataSet(filterTableData(userProfile?.email, !checkedGray,checkedGreen, tableData))
        }
    };
      
    const handleChangeGreen = () => {
        setCheckedGreen(!checkedGreen);
        arrCheckboxStatusSet([checkedGray, !checkedGreen]);
        console.log("swtch cheked green to "+!checkedGreen);
        if (tableData) {
            filteredTableDataSet(filterTableData(userProfile?.email, checkedGray,!checkedGreen, tableData))
        }
    };

    const needUpdateData = async () => {

        if (tableInfoData && tableInfo) {
            const tykoData = await apiLoadTykoTableData(tableInfo.tableIndex, tableInfo.sheetIndex, tableInfoData);
            tykoData.reverse();
            tableDataSet(tykoData);
            
            console.log("=== needUpdateData");
            //filteredTableDataSet(filterTableData(userProfile?.email, checkedGray, checkedGreen, tykoData));
        }
    }
    const cbRating = (val: number, row: number) => {
        ///test
        apiGSUpdateCell('1rYROId7Uhx6Ro7bftbaJmdZCQVKo1TPNVIh_9254RfA', '1272729823', 15, row-1, val.toString());

        console.log("==== cbRating");
        clearData();
        loadData(tableMeta, sheetId);
    }

    const chGrayLabel = ["Показати відхилені", "Show rejected"];
    const chGreenLabel = ["Показати затверджені", "Show approved"];
    return (
        <>
            <TableContainer component={Paper}>
                
                <Typography variant="h5" gutterBottom>Таблиця: {tableInfo?.menuTitles[langId].desc}</Typography>
                <br/>

                {!needEmailFilter || userProfile ?
                <Box sx={{ flexGrow: 1 }}>
                    <ColoredCheckbocksFilter 
                        checkedGray={checkedGray} 
                        handleChangeGray={handleChangeGray}
                        checkedGreen={checkedGreen}
                        handleChangeGreen={handleChangeGreen}
                        langId={langId}
                    />

                <Grid container spacing={2}>
                    <Grid item xs>
                    <div>
                    {filteredTableData && tableInfoData ?
                        filteredTableData.length ?
                        <>
                        <GSTykoTableV2 
                            arr={filteredTableData} 
                            colList={colTitleList(tableInfoData.rowlist)} 
                            rowClicked={rowTableClicked} 
                            needUpdateData={needUpdateData}
                            needRating={sheetId==='specavto'}
                            cbRating={cbRating}
                        />
                        </>
                        :   <Grid 
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h6" gutterBottom>Ви не маєте зареєстрованих запитів на "{userProfile?.email}".</Typography>
                                </Box>
                            </Grid>
                        :
                        <Box sx={{ width: '40px', height: '40px', }}>
                            <CircularProgress />
                        </Box>
                    }
                    </div>
                    </Grid>
                </Grid>
                </Box>
                : null
                /*<Grid 
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" gutterBottom>Вам потрібно залогінитись (за допомогою кнопки нижче) </Typography>
                    <GoogleLoginButton onLoginSuccess={onLoginSuccess} />
                    </Box>
                </Grid>*/
                }

                {tableInfoData ? 
                <TykoTableDetailsPage 
                    arrItem={filteredTableData && openDetailsDialogIndex>=0? filteredTableData[openDetailsDialogIndex].advData : []} 
                    colList={colTitleList(tableInfoData.advRowlist)}
                    open={openDetailsDialogIndex}
                    onClose={handleDetilsDialogClose}
                /> : null}
            </TableContainer>
        </>
    );
}
