// @ts-ignore
import {GoogleSpreadsheet} from "google-spreadsheet";
import creds from "../../../api/google-spreadsheet/med_credentials.json";


export const apiGSUpdateCell = async (tableIndex:string, sheetIndex:string, col: number, row:number, data:string ): Promise<boolean> => {

    try {
        const doc = new GoogleSpreadsheet(tableIndex);

        console.log("gs apiGSUpdateCell");
        await doc.useServiceAccountAuth(creds);
        // loads document properties and worksheets
        await doc.loadInfo();
        const sheet = doc.sheetsById[sheetIndex];

        let lastRow = sheet.rowCount/1;
        console.log("lastRow "+lastRow);
        
        await sheet.loadCells('J1:R'+lastRow); // loads a range of cells
        //console.log("==== sheet.cellStats"+sheet.cellStats); // total cells, loaded, how many <non-empty></non-empty>
        //const rows = await sheet.getRows();
        
        //console.log('======= getCell: row='+row+', col='+col);
        const cell = sheet.getCell(row, col);//K
        cell.value = data;
        await sheet.saveUpdatedCells();
        console.log('Success apiGSUpdateCell');
        
        return true;
    } catch (e) {
        console.error('Error apiGSUpdateCell: ', e);
        return false;
    }
}