import React, {useEffect, useState} from 'react';
// @ts-ignore
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'

import {IMedRequest, loadSpreadsheet, loadSpreadsheetFast, IMedFilter, loadSpreadsheet_filters} from "../api/google-spreadsheet/apiMedTable"
import GSTable from "../mycomponents/GSTable"
import MedFiltersPanel, {IMedTypeFilters} from "../mycomponents/MedFiltersPanel"
import GSTMedDetailsPage from "./GSTMedDetailsPage"
import {useLocalStorage} from "../mycomponents/useLocalStorage"

//import Typography from '@mui/material/Typography';
const Item = styled(Paper)(({ theme }) => ({
    //backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    //...theme.typography.body2,
    //padding: theme.spacing(1),
    //textAlign: 'center',
    //color: theme.palette.text.secondary,
  }));

export default function GSTMedPage() {
    const [arrg, arrgSet] = useState<IMedRequest[] | []>([]);

    const [arrgFiltered, arrgFilteredSet] = useState<IMedRequest[] | []>([]);
    const [arrSity, arrSitySet] = useState<IMedFilter[]>([]);
    
    //const [arrTypeFilters, arrTypeFiltersSet] = useState<IMedTypeFilters[] | []>([]);
    //const [arrSityFilters, arrSityFiltersSet] = useState<IMedTypeFilters[] | []>([]);
    const [arrTypeFilters, arrTypeFiltersSet] = useLocalStorage<IMedTypeFilters[] | []>('arrLStorageType', []);
    const [arrSityFilters, arrSityFiltersSet] = useLocalStorage<IMedTypeFilters[] | []>('arrLStorage', []);

    //const [expandedAccordion, expandedAccordionSet] = React.useState<string | false>('panel1');
    const [expandedAccordion, expandedAccordionSet] = useLocalStorage<string | false>('expandedAccordion', 'panel1');
    const [openDetailsDialogIndex, openDetailsDialogIndexSet] = React.useState(-1);

    const fetchMyAPI = React.useCallback(async () => {
        const arr_filters = await loadSpreadsheet_filters();
        const array: IMedRequest[] = await loadSpreadsheet();
        
        const revarr: IMedRequest[] = array.map(item => item).reverse();
        arrgSet(revarr);
        arrgFilteredSet(revarr);
        arrSitySet(getSitiesArr(array));

        const arrTypeFilters_ = mergeChFiltersArr(arrTypeFilters, arr_filters);
        const arrSityFilters_ = mergeChFiltersArr(arrSityFilters, getSitiesArr(array));
        arrTypeFiltersSet(arrTypeFilters_);
        arrSityFiltersSet(arrSityFilters_);

        filterArrg(revarr, arrTypeFilters_, arrSityFilters_);
    }, []);

    React.useEffect(() => {
        fetchMyAPI();
    }, [fetchMyAPI]);

    function mergeChFiltersArr(filterArr: IMedTypeFilters[] | [], strarr: IMedFilter[], checked: boolean=true) {
        
        function ArrInclude( arr: IMedTypeFilters[] | [], str: string ) : IMedTypeFilters | null {
            let res: IMedTypeFilters | null = null;
            arr.forEach((item) => {
                //console.log("===2ArrInclud "+item.name+"/"+str);
                if ( item.name === str )
                {
                    //console.log("===equ "+item.name+"/"+str);
                    res = item;
                }
            })
            //console.log("===>>>>res "+res);
            return res;
        }

        let res: IMedTypeFilters[] = [];
        strarr.forEach((stritem,index) => {
            //console.log("===2"+item);
            let it = ArrInclude( filterArr, stritem.name,);
            
            if ( it )
            {
                it.id = index;
                it.name = stritem.name;
                it.status = stritem.status;
                res.push(it);
                //console.log("===merge "+stritem);
            }
            else
            {
                res.push({ id: index, name: stritem.name, status: stritem.status, ch: true });
                //console.log("===add "+stritem);
            }
        })
        return res;
    }

    function makeChFiltersArr(arr: IMedFilter[], checked: boolean=true) {
        let res: IMedTypeFilters[] = [];
        arr.forEach((item,index) => {
            //console.log("===2"+item);
            res.push({ id: index, name: item.name, status: item.status, ch: checked });
        })
        return res;
    }

    const getSitiesArr = (arr: IMedRequest[]) => {
        let newArr: IMedFilter[] = [];
        arr.forEach((item) => {
            const {sity,title,type} = item;

            function newArrIincludes_temp(arr: IMedFilter[], sity: string) {
                let res: boolean=false;
                arr.forEach((item,index) => {
                    if(item.name===sity)
                        res = true;
                })
                return res;
            }

            if (newArr && newArrIincludes_temp(newArr, sity ) ) {
                
            } else {
                newArr.push({
                    name: sity,
                    status: "",
                });
                //console.log("========= "+type);
            }
            //console.log("------! "+type);
        }) 
        //console.log("###### count "+ newArr?newArr.length:-1);
        return newArr;
    }

    const hasFilter = (arr: IMedTypeFilters[]) : boolean => {
        let res: boolean = false;
        arr.forEach((item) => {
            if ( !item.ch )
                res = true;
        })
        return res;
    }

    const filterArrg = (arrmain: IMedRequest[], arrTypeFilters_: IMedTypeFilters[], arrSityFilters_: IMedTypeFilters[]) => {
        const wordsArr = arrTypeFilters_.map((item) => 
            item.ch ? item.name : null
        )
        const wordsArr2 = arrSityFilters_.map((item) => 
            item.ch ? item.name : null
        )
        console.log("=start filterArrg");
        let newArr: IMedRequest[] = [];
        arrmain.forEach((item) => {
            const {sity,title,type} = item;
            if (wordsArr && (wordsArr.includes( type ) || (type.startsWith("Інше.") ? wordsArr.includes( "Інше." ) : false)) && wordsArr2 && wordsArr2.includes( sity ) ) {
            //if (wordsArr2 && wordsArr2.includes( sity ) ) {
                newArr.push(item);
                //console.log("=========## "+type);
            } 
            else {
                //console.log("=========!!!!!!!!!! "+sity);
            }
            
        }) 
        //console.log("###### count "+ newArr?newArr.length:-1);
        arrgFilteredSet(newArr);
    }

    const clbChangeArr = (arrFilters: IMedTypeFilters[]) => {
        arrTypeFiltersSet(arrFilters);
        filterArrg(arrg, arrTypeFilters, arrSityFilters);
    }

    const clbChangeSityFilters = (arrFilters: IMedTypeFilters[]) => {
        arrSityFiltersSet(arrFilters);
        filterArrg(arrg, arrTypeFilters, arrSityFilters);
    }

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        expandedAccordionSet(newExpanded ? panel : false);
    };

    const onSelectSityAll = () => {
        const arr = makeChFiltersArr(getSitiesArr(arrg));
        arrSityFiltersSet(arr);
        filterArrg(arrg, arrTypeFilters, arr);
    }
    const onUnselectSityAll = () => {
        const arr = makeChFiltersArr(getSitiesArr(arrg), false);
        arrSityFiltersSet(arr);
        filterArrg(arrg, arrTypeFilters, arr);
    }
    const onSelectTypeAll = () => {
        const wordsArr = arrTypeFilters.map((item: IMedTypeFilters) => {
            const obj = {
                name: item.name,
                status: item.status,
            };
            return obj;            
        })
        const arr = makeChFiltersArr(wordsArr);
        arrTypeFiltersSet(arr);
        filterArrg(arrg, arr, arrSityFilters);
    }
    const onUnselectTypeAll = () => {
        const wordsArr = arrTypeFilters.map((item: IMedTypeFilters) => {
            const obj = {
                name: item.name,
                status: item.status,
            };
            return obj; 
        })
        const arr = makeChFiltersArr(wordsArr, false);
        arrTypeFiltersSet(arr);
        filterArrg(arrg, arr, arrSityFilters);
    }

    const handleDetilsDialogClose = () => {
        openDetailsDialogIndexSet(-1);
    };

    const rowTableClicked = (index: number) => {
        openDetailsDialogIndexSet(index);
    }

    return (
        <TableContainer component={Paper}>
            <Typography variant="h3" gutterBottom>База даних: Запити потреб v5</Typography>
            <Typography variant="h5" gutterBottom>Медичний підкомітет Координаційного Комітету подолання гуманітарної катастрофи</Typography>
            <Typography variant="body1">Відображено записів: {arrgFiltered.length}</Typography>
            <br/>
            <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid>
                <Item>
                <Paper sx={{ width: 330, overflow: 'auto' }}>
                    <Accordion expanded={expandedAccordion === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        >
                        <Typography>
                            Фільтр: Місто {hasFilter(arrSityFilters)?<Typography variant="body1" color="red" >[є філтер]</Typography>:null}
                        </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        {arrSityFilters && arrSityFilters.length?
                            <>
                            <Button size="small" variant="outlined" onClick={onSelectSityAll}>Віділити все</Button>
                            <Button size="small" variant="outlined" onClick={onUnselectSityAll}>Скинути все</Button>
                            <MedFiltersPanel title="Фільтр: Місто" arr={arrSityFilters} clbChangeArr={clbChangeSityFilters} />
                            </> :
                            <CircularProgress />
                        }
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expandedAccordion === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography>
                            Фільтр: Тип {hasFilter(arrTypeFilters)?<Typography variant="body1" color="red" >[є філтер]</Typography>:null}
                        </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        {arrTypeFilters && arrTypeFilters.length?
                            <>
                            <Button size="small" variant="outlined" onClick={onSelectTypeAll}>Віділити все</Button>
                            <Button size="small" variant="outlined" onClick={onUnselectTypeAll}>Скинути все</Button>
                            <MedFiltersPanel title="Фільтр_3: Тип" arr={arrTypeFilters} clbChangeArr={clbChangeArr} />
                            </> :
                            <CircularProgress />
                        }
                        </AccordionDetails>
                    </Accordion>
                </Paper>
                </Item>
                </Grid>
                <Grid item xs>
                <Item>
                {arrgFiltered && arrgFiltered.length ?
                    <GSTable arrg={arrgFiltered} rowClicked={rowTableClicked} hasSityFilter={hasFilter(arrSityFilters)} hasTypeFilter={hasFilter(arrTypeFilters)} />
                    :
                    <CircularProgress />
                }
                </Item>
                </Grid>
            </Grid>
            </Box>
            <GSTMedDetailsPage 
                arrItem={arrgFiltered && openDetailsDialogIndex>=0? arrgFiltered[openDetailsDialogIndex] : null} 
                open={openDetailsDialogIndex}
                onClose={handleDetilsDialogClose}
            />
            
            
        </TableContainer>
        
    );
}