import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';

import TTHeader from '../TykoTables/TTHeader';
import Footer from './Footer';
import { IUserProfile } from '../api/apiAdministration';
import ProjectCard from './ProjectCard2';
import { Container } from '@mui/system';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import CasesSharpIcon from '@mui/icons-material/CasesSharp';
import LanguageIcon from '@mui/icons-material/Language';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import Tooltip from '@mui/material/Tooltip';

import Icon1 from "../../assets/img/icons/2039004.png";
import Icon2 from "../../assets/img/icons/2927516.png";
import Icon3 from "../../assets/img/icons/2621824.png";
import Icon4 from "../../assets/img/icons/2038999.png";

import IconTable from "../../assets/img/icons2/tables.png";
import IconForm from "../../assets/img/icons2/forms.png";
import IconAnalitics from "../../assets/img/icons2/analitics.png";
import RotaryLogo from "../../assets/img/temp/RotaryLogo.png";

import CoordComForms from '../GuiComponents/CoordComForms';
import CoordComTables from '../GuiComponents/CoordComTables';
import D2232ProjectsHome from './D2232ProjectsHome';
import CoordComTablePage from "../../pages/CoordComTablePage";

const userProfile:IUserProfile = {
    userName: 'tyko',
    email: 'tykogm7@gmail.com',
    imgUrl: '',
    committee: null,
}

export default function MainPage() {
    const pageList = ['project', 'forms', 'tables', 'table1'];
    const [currPage, setCurPage] = useState<number>(0);
    
    const project = () => {
        switch(pageList[currPage]) {
  
          case "project":   return <D2232ProjectsHome />;
          case "forms":   return <CoordComForms onClick={onClickForm}/>;
          case "tables":   return <CoordComTables userProfile={userProfile} onClick={onClickTable}/>;
          case "table1":   return <CoordComTablePage />;
  
          default:      return <h1>No project match</h1>
        }
      }


    const onChangeMainPage = (page:string) => {
        const newPage = pageList.indexOf(page);
        setCurPage(newPage);
    }
    const onClickForm = (n:number) => {
        //setCurPage(n);
    }
    const onClickTable = (n:number) => {
        //setCurPage(n);
    }

    return (
        <Box  height="100vh" display="flex" flexDirection="column" sx={{bgcolor: 'lightgray'}} >
            <TTHeader 
                title='Проєкти Дистрикту 2232' 
                userProfile={userProfile}
                language='Укр' 
                onSwitchLanguage={onSwitchLanguage} 
                pages={['1']}
                onSwitchPages={onSwitchPages}
                onLoginSuccess={onLoginSuccess}
                onLogoutSuccess={onLogoutSuccess}
                needEmailFilter={true}
                needTranslate={true}
            />
            <Box  height="100%" display="flex" flexDirection="row" >
                <Box  width="60px" display="flex" flexDirection="column" sx={{bgcolor: 'gray', mt: '4px'}} >
                    <Stack direction="column" spacing={1}>

                    <IconButton aria-label="delete"  color="secondary">
                        <Tooltip title="Розгорнути панель">
                        <ArrowBackIos />
                        </Tooltip>
                    </IconButton>

                    <IconButton aria-label="delete">
                        <Tooltip title="Проєкти">
                        <CasesSharpIcon sx={{color: "#303030", "&:hover": { color: "blue" } }}/>
                        </Tooltip>
                    </IconButton>
                    
                    <IconButton color="secondary" aria-label="add an alarm" onClick={() => onChangeMainPage('project')} >
                        <Tooltip title="Координаційний комітет">
                        <BeenhereIcon sx={{color: "#303030", "&:hover": { color: "blue" } }}/>
                        </Tooltip>
                    </IconButton>

                    <Box sx={{pl: '10px'}} >
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => onChangeMainPage('tables')} >
                            <Tooltip title="Мої запити">
                                <img height='40px' src={IconTable} />
                            </Tooltip>
                        </IconButton>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => onChangeMainPage('forms')} >
                            <Tooltip title="Зробити запит">
                                <img height='40px' src={IconForm} />
                            </Tooltip>
                        </IconButton>
                        <IconButton color="secondary" aria-label="add an alarm">
                            <Tooltip title="Рекомендації">
                                <img height='40px' src={RotaryLogo} />
                            </Tooltip>
                        </IconButton>
                        <IconButton color="secondary" aria-label="add an alarm">
                            <Tooltip title="Аналітика">
                                <img height='30px' src={IconAnalitics} />
                            </Tooltip>
                        </IconButton>
                    </Box>

                    <IconButton color="success" aria-label="add to shopping cart">
                        <CalendarViewDayIcon sx={{color: "#303030", "&:hover": { color: "blue" } }} onClick={() => onChangeMainPage('table1')}/>
                    </IconButton>
                    </Stack>
                </Box>
                <Box  width="100%" display="flex" flexDirection="column" sx={{}} >
                    
                <div>{ project() }</div>

                </Box>
            </Box>
            <Footer />
            
        </Box>
    );
}


const onSwitchLanguage = () => {
}
const onSwitchPages = (page:string) => {
}
const onLoginSuccess = (userData:IUserProfile) => {
}
const onLogoutSuccess = () => {
}