import React, {useState, useRef, useEffect} from 'react';
import { useSearchParams } from "react-router-dom";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import TTHeader from '../TykoTables/TTHeader';
import GoogleLoginButton from '../GoogleLogin/GoogleLoginButton';
import TykoTableFrameV2 from '../SuperUser/TykoTableFrameV2';
import {ISuperUserProfile, IUserProfile, getTableNameByIndex, isSuperUser} from '../api/apiAdministration';

export default function D2232MyCoordCom() {
    const [searchParams, setSearchParams] = useSearchParams();
    const isDebug:boolean = searchParams.get('debug') === "true";

    const mainFrameSize = useRef<HTMLDivElement>(null);
    const [currWidth, setCurWidth] = useState<number|undefined>(0);
    const [currTableName, setCurrTableName] = useState<string>('coordcom');
    const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);

    const resizeHandler = () => {
        const { clientHeight, clientWidth } = mainFrameSize.current || {};
        setCurWidth(clientWidth);
      };
    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
        return () => {
            if (mainFrameSize && mainFrameSize.current)
                mainFrameSize.current.removeEventListener("resize", resizeHandler);
        };
    }, []);

    const onSwitchLanguage = () => {
    }
    const onSwitchPages = (page:string) => {
    }
    const onLoginSuccess = (userData:IUserProfile) => {
        /*const fakeUserProfile:IUserProfile = {
            userName: "test",
            email: "filippenko.pavel@gmail.com",
            imgUrl: "",
            committee: null,
        };
        setUserProfile(fakeUserProfile);*/

        setUserProfile(userData);
        console.log("====== onLoginSuccess="+userData?.email);
        //fetchMyAPI();
        //if (tableData) {
            //filteredTableDataSet(filterTableData(userData?.email, checkedGray,!checkedGreen, tableData))
        //}
    }
    const onLogoutSuccess = () => {
        setUserProfile(null);
        //if (tableData) {
            ///filteredTableDataSet(filterTableData('', checkedGray,!checkedGreen, tableData))
        //}
    }
    
    const isLogined = () => {
        return userProfile !== null;
    }
    const isKKUser= () => {
        return isSuperUser(userProfile?.email);
    }

    const isVertical = currWidth ? currWidth > 700 : true;
    const direction = isVertical ? 'column' : 'row';
    const direction2 = !isVertical ? 'column' : 'row';
    return (
        <Box height="100%" display="flex" flexDirection="column" sx={{bgcolor: 'white', minHeight: "100%" }} >
            <TTHeader 
                title='База даних: Запити Координаційного Комітету' 
                userProfile={userProfile}
                language='Укр' 
                onSwitchLanguage={onSwitchLanguage} 
                pages={['1']}
                onSwitchPages={onSwitchPages}
                onLoginSuccess={onLoginSuccess}
                onLogoutSuccess={onLogoutSuccess}
                needEmailFilter={true}
                needTranslate={false}
            />
            <Box ref={mainFrameSize} height="100%" display="flex" flexDirection={direction2} sx={{}}>
                {/*<LeftMenuPanel iconFirst={IconTable} onClickButton={onChangeMainPage} isVertical={isVertical}/>    */}    
                <Box  width="100%" display="flex" flexDirection="column" sx={{}} >
                
                {!isLogined() ?
                    <Grid 
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '100vh' }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" gutterBottom>Вам потрібно залогінитись (за допомогою кнопки нижче) </Typography>
                        <GoogleLoginButton onLoginSuccess={onLoginSuccess} />
                        </Box>
                    </Grid> : null
                }
                
                <>
                    <Grid container direction="column" alignItems="center" justifyContent="center">
                    {userProfile && userProfile.committee && isKKUser()
                            ? <Typography variant="h6">{userProfile.committee.name}, ви як {userProfile.committee.desc} маєте доступ до всіх записів таблиці</Typography>
                            : <Typography variant="h6">Всі запити, що зареєстровані на {userProfile?.email}</Typography> 
                        }
                    </Grid>
                    {currTableName ? 
                        <TykoTableFrameV2 needEmailFilter={!isKKUser()} sheetId={currTableName} userProfile={userProfile} isDebug={isDebug}/> 
                        : <Typography variant="h4" color="red">error: таблиця в розробці</Typography>
                    }
                </>
                
                </Box>
                
            </Box>
        </Box>
    );
    
}
