import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import { blue } from '@mui/material/colors';

import FoodCardIcon from "../../assets/img/temp/FoodCardIcon.png";
import RotaryLogo from "../../assets/img/temp/RotaryLogo.png";


interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ProjectCard2({title, subtitle, desc, icon, icon2, onClick}:
        {title:string, subtitle:string, desc:string, icon:string, icon2:string, onClick:()=>void}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 345, bgcolor: blue[50]}}>
      <CardActionArea onClick={()=>onClick()} >
        <CardContent>
          
            <Grid container spacing={3} display="flex" flexDirection="row">
              <Grid item>
                <img height='80px' src={icon2} />
              </Grid>
              <Grid item>
                <img height='80px' src={icon} />
              </Grid>
              
            
            </Grid>
            <Grid item>
                <Typography variant='h6' color={'darkblue'}>{title}</Typography>
                <Typography variant='body2'>({subtitle})</Typography>
                <Typography variant='body1'>{desc}</Typography>
              </Grid>

        </CardContent>
    </CardActionArea>
    </Card>
  );
}