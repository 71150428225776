import React, {useState, useRef, useEffect} from 'react';
import { useSearchParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import TTHeader from '../TykoTables/TTHeader';
import GoogleLoginButton from '../GoogleLogin/GoogleLoginButton';
import CoordComTables from '../GuiComponents/CoordComTables';
import TykoTableFrame from '../TykoTables/TykoTableFrame';
import TykoTableFrameV2 from '../SuperUser/TykoTableFrameV2';
import {ISuperUserProfile, IUserProfile, getTableNameByIndex, isSuperUser, isSubcommitteeUser, isAnyUser} from '../api/apiAdministration';
import LeftMenuPanel from '../GuiComponents/LeftMenuPanel';

import IconTable from "../../assets/img/icons2/tables.png";


export default function D2232MyTables() {
    const [searchParams, setSearchParams] = useSearchParams();
    const paramTableName:string|null = searchParams.get('t');

    const formList = ['forms','fmed', 'flog', 'fspec'];//'ftech', 'fspec', 'fwash', 'fpeople', 'fcoordcom'];
    const mainFrameSize = useRef<HTMLDivElement>(null);
    const [currWidth, setCurWidth] = useState<number|undefined>(0);

    const defaultPageIndex = paramTableName ? formList.indexOf(paramTableName) : 0;
    const [currPage, setCurPage] = useState<number>(defaultPageIndex);
    const [currTableName, setCurrTableName] = useState<string>(getTableNameByIndex(defaultPageIndex-1));//logistic
    
    //access
    const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);

    const resizeHandler = () => {
        const { clientHeight, clientWidth } = mainFrameSize.current || {};
        setCurWidth(clientWidth);
      };
    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
        return () => {
            if (mainFrameSize && mainFrameSize.current)
                mainFrameSize.current.removeEventListener("resize", resizeHandler);
        };
    }, []);

    const onChangeMainPage = (page:string) => {
        const formIndex = formList.indexOf(page);
        setCurPage(formIndex);
        setCurrTableName(getTableNameByIndex(formIndex-1))
        console.log("==== onChangeMainPage="+getTableNameByIndex(formIndex-1))
    }

    const onClickTable = (n:number) => {
        setCurPage(n+1);
        setCurrTableName(getTableNameByIndex(n))
        console.log("==== onClickTable="+getTableNameByIndex(n))
    }
    const onSwitchLanguage = () => {
    }
    const onSwitchPages = (page:string) => {
    }
    const onLoginSuccess = (userData:IUserProfile) => {
        setUserProfile(userData);
        console.log("====== onLoginSuccess="+userData?.email);
        //fetchMyAPI();
        //if (tableData) {
            //filteredTableDataSet(filterTableData(userData?.email, checkedGray,!checkedGreen, tableData))
        //}
    }
    const onLogoutSuccess = () => {
        setUserProfile(null);
        //if (tableData) {
            ///filteredTableDataSet(filterTableData('', checkedGray,!checkedGreen, tableData))
        //}
    }

    const onlyForPeopleTable = () => {//тимчасово для таблиці Люди!!!!!!!!!!!!!!!!!!
        return currTableName==="people";//тимчасово для таблиці Люди!!!!!!!!!!!!!!!!!!
    }
    const needEmailFilter = () => {
        const isLogined = userProfile;
        const isRegUser = isKKUser() || isSKUser();
        const haveSuccess = (isLogined && isRegUser) || onlyForPeopleTable();

        return !haveSuccess;
    }
    const isLogined = () => {
        return userProfile !== null;
    }
    const isAnyUser2= () => {
        return isAnyUser(userProfile?.email);
    }
    const isKKUser= () => {
        return isSuperUser(userProfile?.email);
    }
    const isSKUser = () => {
        return isSubcommitteeUser(userProfile?.email, formList[currPage]);
    }
    
    const isVertical = currWidth ? currWidth > 700 : true;
    const direction = isVertical ? 'column' : 'row';
    const direction2 = !isVertical ? 'column' : 'row';
    return (
        <Box height="100%" display="flex" flexDirection="column" sx={{bgcolor: 'white', minHeight: "100%" }} >
            <TTHeader 
                title='Проєкти Дистрикту 2232' 
                userProfile={userProfile}
                language='Укр' 
                onSwitchLanguage={onSwitchLanguage} 
                pages={['1']}
                onSwitchPages={onSwitchPages}
                onLoginSuccess={onLoginSuccess}
                onLogoutSuccess={onLogoutSuccess}
                needEmailFilter={true}
                needTranslate={false}
            />
            <Box ref={mainFrameSize} height="100%" display="flex" flexDirection={direction2} sx={{}}>
                <LeftMenuPanel iconFirst={IconTable} onClickButton={onChangeMainPage} isVertical={isVertical}/>
            
                <Box  width="100%" display="flex" flexDirection="column" sx={{}} >
                
                    {/*currPage={currPage};<br/>
                    currTableName={currTableName};<br/>
                    userProfile={userProfile?userProfile.email:"-"};<br/>
                    */}
                
                {!isLogined() ?
                    <Grid 
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '100vh' }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" gutterBottom>Вам потрібно залогінитись (за допомогою кнопки нижче)</Typography>
                        <GoogleLoginButton onLoginSuccess={onLoginSuccess} />
                        </Box>
                    </Grid> : null
                }
                {currPage === 0 ?
                <CoordComTables userProfile={userProfile} onClick={onClickTable}/>
                :
                <>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    {userProfile && userProfile.committee && (isKKUser() || isSKUser())
                        ? <Typography variant="h6">{userProfile.committee.name}, ви як {userProfile.committee.desc} маєте доступ до всіх записів таблиці</Typography>
                        : <Typography variant="h6">Гість, ви маєте доступ тільки до своїх записів таблиці, що зареєстровані на {userProfile?.email}</Typography> 
                    }
                    {onlyForPeopleTable() ?
                        <Typography variant="h6">Тимчасово відкрито доступ ВСІМ на перегляд.</Typography>
                        : false
                    }
                </Grid>
                {currTableName ? 
                    <TykoTableFrameV2 needEmailFilter={needEmailFilter()} sheetId={currTableName} userProfile={userProfile} isDebug={false}/> 
                    : <Typography variant="h4" color="red">error: таблиця вказана не вірно. Виберіть актуальну таблицю.</Typography>
                }
                </>
                                
                }
                </Box>
                
            </Box>
            {//<Footer />
            }
        </Box>
    );
    
}
