import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import TTHeader from '../TykoTables/TTHeader'
import { IUserProfile } from '../api/apiAdministration';
import ProjectCard from './ProjectCard2';
import { Container } from '@mui/system';
import LanguageIcon from '@mui/icons-material/Language';

export default function Footer() {
    
    return (
        <Box sx={{height: '40px', bgcolor: '#4dabf5', p: '8px' }}>    
            <LanguageIcon sx={{color: "white" }}/><Link sx={{color: 'white' }}>http://rotary.org.ua</Link>
        </Box>
    );
}
