import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';

import TTHeader from '../TykoTables/TTHeader';
import Footer from './Footer';
import { IUserProfile } from '../api/apiAdministration';
import ProjectCard2 from './ProjectCard2';
import { Container } from '@mui/system';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import CasesSharpIcon from '@mui/icons-material/CasesSharp';
import LanguageIcon from '@mui/icons-material/Language';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import Tooltip from '@mui/material/Tooltip';

import Icon1 from "../../assets/img/icons/2039004.png";
import Icon2 from "../../assets/img/icons/2927516.png";
import Icon3 from "../../assets/img/icons/2621824.png";
import Icon4 from "../../assets/img/icons/2038999.png";

import IconTable from "../../assets/img/icons2/tables.png";
import IconForm from "../../assets/img/icons2/forms.png";
import IconAnalitics from "../../assets/img/icons2/analitics.png";
import RotaryLogo from "../../assets/img/temp/RotaryLogo.png";

const userProfile:IUserProfile = {
    userName: 'tyko',
    email: 'tykogm7@getMaxListeners.com',
    imgUrl: '',
    committee: null,
}

const onClick2 = () => {

}

export default function D2232ProjectsHome() {
    const [page, setpage] = useState<number>(0);
    
    return (

                <Box  width="100%" display="flex" flexDirection="column" sx={{pt: '20px'}} >
                    <Container >
                    <Typography variant="h4" color="darkblue">Координаційний комітет</Typography>
                    <Grid container spacing={1} sx={{maxWidth: '1280px', mt: '10px', p: '10px'    }}> 
                        <Grid item xs={3}>
                            <ProjectCard2 title='Мої проекти та запити' subtitle='переглянути статус запитів' desc='' icon={Icon1} icon2={IconTable} onClick={onClick2} />
                        </Grid>
                        <Grid item xs={3}>
                            <ProjectCard2 title='Зробити запит' subtitle='всі форми для запитів' desc='' icon={Icon2} icon2={IconForm} onClick={onClick2} />
                        </Grid>
                        <Grid item xs={3}>
                            <ProjectCard2 title='Правила та рекомендації' subtitle='Вимоги до проектів' desc='' icon={Icon3} icon2={RotaryLogo} onClick={onClick2} />
                        </Grid>
                        <Grid item xs={3}>
                            <ProjectCard2 title='Аналітика клубів' subtitle='Взаємодія, фотозвіти та подяки' desc='' icon={Icon4} icon2={IconAnalitics} onClick={onClick2} />
                        </Grid>
                    </Grid>
                    </Container>
                </Box>
           
    );
}

const onSwitchLanguage = () => {
}
const onSwitchPages = (page:string) => {
}
const onLoginSuccess = (userData:IUserProfile) => {
}
const onLogoutSuccess = () => {
}