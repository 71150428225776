import emailjs from '@emailjs/browser';

export const sendEmail = (id:string, send_to:string, user_name:string|undefined, status:string|undefined, short_desc:string|undefined, adv_message:string|undefined) => {
    var templateParams = {
        send_to: send_to,
        user_name: user_name,
        id: id,
        short_desc: short_desc,
        status: status,
        adv_message: adv_message,
    };
    emailjs.init("7mKMPxB_RlU16SESz");
    emailjs.send('service_7ffs245', 'template_yy6mepb', templateParams)
        .then(function(response) {
           console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
           console.log('FAILED...', error);
        });
}