import React, {useState} from "react";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

export interface IMedTypeFilters {
    id: number,
    name: string,
    status: string,
    ch: boolean,
};

export default function MedFiltersPanel({title, arr, clbChangeArr}: {title: string, arr: IMedTypeFilters[], clbChangeArr: Function }) {
    
    const handleToggle = (ind: number) => () => {
        const newArr = [...arr];
        newArr[ind].ch = !arr[ind].ch;
        
        clbChangeArr(newArr);
    };

    return (
        <List dense component="div" role="list">
            {arr.map((item: IMedTypeFilters, index: number) => {
            const labelId = `transfer-list-item-${item.name}-label`;
            const statusColor = item.status==="виконано"?'lightgreen': item.status==="частково"?'yellow':'white';

            return (
                <ListItem
                key={item.id}
                role="listitem"
                button
                onClick={handleToggle(index)}
                >
                <ListItemIcon>
                    <Checkbox
                    checked={item.ch}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                    />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${item.id}. ${item.name}` } style={{ backgroundColor: statusColor }}/>
                </ListItem>
            );
            })}
            <ListItem />
        </List>
    )
}