import React, {useEffect, useState} from 'react';
// @ts-ignore
import {GoogleSpreadsheet} from "google-spreadsheet";
import creds from "./med_credentials.json";

const SPREADSHEET_ID = "1UDfqFDfZ6pH8yuTu2oiQ297K4S_pcdbuRETdu5FG8rI";
const SHEET_ID = "881515583";//DBTable

export interface IMedRequest {
    id: number,
    date: string, //Позначка часу, 
    sity: string, 
    name: string, //м'я, Прізвище контактної особи, 
    title: string, //Назва запиту,
    url: string, //Додати файл запиту
    urlAdv: string, //Додати Додатки Запиту
    type: string, //Класифікатор оптових закупівель медичного обладнання:
    desc: string, //Примітки
    num8: string, //Електронна адреса
    num: string, //Кількість в запиті
    type2: string, //Класифікатор медичних витратних матеріалів
    num11: string, //Номер відділення Нової Пошти, місто, адреса, ПІП, телефон
    num12: string, //Прізвище, ім'я
    num13: string, //Обґрунтування запиту (коротко)
    desc2: string, //Додаткові пояснення
    num15: string, //Наявність конфлікту інтересів у ротарійців-учасників проекту
    num16: string, //Телефон
    num17: string, //ПРОДАВЕЦЬ ОБЛАДНАННЯ
    urlKom: string, //Надайте комерційну пропозицію продавця, якщо вона надана окремим файлом
    //num20: string, //ПОТРЕБА ВАШОЇ ГРОМАДИ
};

export interface IMedFilter {
    name: string, // фільтр
    status: string, //статус
};

export const loadSpreadsheet = async (): Promise<IMedRequest[]> => {
    try {
        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        console.log("gs loadSpreadsheet");
        await doc.useServiceAccountAuth(creds);
        // loads document properties and worksheets
        await doc.loadInfo();
        const sheet = doc.sheetsById[SHEET_ID];

        let lastRow = sheet.rowCount/1;
        console.log("lastRow "+lastRow);
        await sheet.loadCells('A1:T'+lastRow); // loads a range of cells
        console.log(sheet.cellStats); // total cells, loaded, how many <non-empty></non-empty>
        //const rows = await sheet.getRows();

        let arr: IMedRequest[] = [];
        for (let r = 1; r < lastRow; r++) {
            const vsity = sheet.getCell(r, 1).value; //Ваш Ротарі Клуб, 
            if (vsity === null ) continue;
            arr.push({
                id: r,
                date: sheet.getCell(r, 0).formattedValue, 
                sity: sheet.getCell(r, 1).value, 
                name: sheet.getCell(r, 2).value, 
                title: sheet.getCell(r, 3).value, 
                url: sheet.getCell(r, 4).value,
                urlAdv: sheet.getCell(r, 5).value, 
                type: sheet.getCell(r, 6).value, 
                desc: sheet.getCell(r, 7).value,
                num8: sheet.getCell(r, 8).value,
                num: sheet.getCell(r, 9).value,
                type2: sheet.getCell(r, 10).value,
                num11: sheet.getCell(r, 11).value,
                num12: sheet.getCell(r, 12).value, 
                num13: sheet.getCell(r, 13).value,  
                desc2: sheet.getCell(r, 14).value, 
                num15: sheet.getCell(r, 15).value,
                num16: sheet.getCell(r, 16).value, 
                num17: sheet.getCell(r, 17).value, 
                urlKom: sheet.getCell(r, 18).value,
                //num20: sheet.getCell(r, 20).value, 

            });
            //console.log("gs loadSpreadsheet- "+r+"]"+sheet.getCell(r, 1).value);
        }
        
        return arr;
    } catch (e) {
        console.error('Error LoadDocInfo: ', e);
        return [];
    }
};

export const loadSpreadsheet_filters = async (): Promise<IMedFilter[]> => {
    const SHEET_ID2 = "808942929";//filters
    try {
        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        console.log("gs loadSpreadsheet_f");
        await doc.useServiceAccountAuth(creds);
        // loads document properties and worksheets
        await doc.loadInfo();

        const sheet = doc.sheetsById[SHEET_ID2];

        let lastRow = sheet.rowCount/1;
        await sheet.loadCells('B2:D'+lastRow); // loads a range of cells
        console.log("gs loadSpreadsheet_stats-"+JSON.stringify(sheet.cellStats)); // total cells, loaded, how many non-empty

        let arr: IMedFilter[] = [];
        //arr.push("Інше:");
        for (let r = 2; r < lastRow; r++) {
            const name = sheet.getCell(r, 1).value;
            //console.log("===1"+name);
            //console.log("vsity="+vsity+", vdesc="+vdesc);
            if (name === null) continue;

            //console.log("gs loadSpreadsheet-"+sheet.getCell(r, 1).value+" / "+sheet.getCell(r, 3).value);
            arr.push({
                name: sheet.getCell(r, 1).value,
                status: sheet.getCell(r, 3).value,
            });
        }
        
        return arr;
    } catch (e) {
        console.error('Error LoadDocInfo: ', e);
        return [];
    }
};

export const loadSpreadsheetFast = async (): Promise<IMedRequest[]> => {
    let arr: IMedRequest[] = [];
    /*arr.push({
        id: 1,
        //date: "1",
        sity: "Київ", 
        name: "1", 
        title: "1",
        //num4: "1",
        //num5: "1",
        //num6: "1",
        //num7: "1",
        //num8: "1",
        type: "Операційні столи рентгенпрозорі.",
        //num10: "1",,
        desc: "1",
        //desc2: "1",
    });
    arr.push({
        id: 2,
        //date: "1",
        sity: "Київ", 
        name: "2", 
        title: "2",
        //num4: "1",
        //num5: "1",
        //num6: "1",
        //num7: "1",
        //num8: "1",
        type: "Операційні столи рентгенпрозорі.",
        //num10: "1",,
        desc: "2",
        //desc2: "1",
    });
    arr.push({
        id: 3,
        //date: "1",
        sity: "Київ", 
        name: "2", 
        title: "2",
        //num4: "1",
        //num5: "1",
        //num6: "1",
        //num7: "1",
        //num8: "1",
        type: "Дерматоми, перфоратори.",
        //num10: "1",,
        desc: "2",
        //desc2: "1",
    });       
    arr.push({
        id: 4,
        //date: "1",
        sity: "Львів", 
        name: "2", 
        title: "2",
        //num4: "1",
        //num5: "1",
        //num6: "1",
        //num7: "1",
        //num8: "1",
        type: "Набори для краніотоміі BBraun.",
        //num10: "1",,
        desc: "2",
        //desc2: "1",
    }); */
    return arr;
};
