import React, {useEffect, useState} from 'react';
// @ts-ignore
import {GoogleSpreadsheet} from "google-spreadsheet";
import creds from "./med_credentials.json";

const SPREADSHEET_ID = "1Xkn077OPHZZ_MvWV6N4agn-zZgFLluqwdekdye8PJPM";
const SHEET_ID = "1971832035";//DBTable

export interface ITableRequest {
    id: number,
    date: string, //Позначка часу, 
    status: string, //Статус: Затверджено/Відхилено
    name: string, //Імя, Прізвище
    rclub: string, //Клуб
    aidtypy: string, //Види допомоги: 
    destination: string, //Кому призначена допомога
    check: string, //Сума допомоги, грн
    desc: string, //Короткий опис запиту 
    longdesc: string, //Повний опис запиту
    note: string, //Примітка
    file: string, //Файл запиту 
    files: string, //Інші файли запиту
    email: string, //Електронна адреса
    phone: string, //Телефон
    colorrow: string, //колір рядка
};

export interface IMedFilter {
    name: string, // фільтр
    status: string, //статус
};

export const loadSpreadsheet = async (): Promise<ITableRequest[]> => {
    try {
        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        console.log("gs loadSpreadsheet");
        await doc.useServiceAccountAuth(creds);
        // loads document properties and worksheets
        await doc.loadInfo();
        const sheet = doc.sheetsById[SHEET_ID];

        let lastRow = sheet.rowCount/1;
        console.log("lastRow "+lastRow);
        await sheet.loadCells('A1:T'+lastRow); // loads a range of cells
        console.log(sheet.cellStats); // total cells, loaded, how many <non-empty></non-empty>
        //const rows = await sheet.getRows();

        let arr: ITableRequest[] = [];
        for (let r = 1; r < lastRow; r++) {
            const data = sheet.getCell(r, 0).value; //Ваш Ротарі Клуб, 
            if (data === null ) continue;
            arr.push({
                id: r+1,
                date: sheet.getCell(r, 0).formattedValue, 
                status: sheet.getCell(r, 1).value, 
                name: sheet.getCell(r, 2).value, 
                rclub: sheet.getCell(r, 3).value, 
                aidtypy: sheet.getCell(r, 4).value,
                destination: sheet.getCell(r, 5).value, 
                check: sheet.getCell(r, 6).value, 
                desc: sheet.getCell(r, 7).value,
                longdesc: sheet.getCell(r, 8).value,
                note: sheet.getCell(r, 9).value,
                file: sheet.getCell(r, 10).value,
                files: sheet.getCell(r, 11).value,
                email: sheet.getCell(r, 12).value, 
                phone: sheet.getCell(r, 13).value,
                colorrow: sheet.getCell(r, 14).value,
            });
            //console.log("gs loadSpreadsheet- "+r+"]"+sheet.getCell(r, 1).value);
        }
        
        return arr;
    } catch (e) {
        console.error('Error LoadDocInfo: ', e);
        return [];
    }
};

//========================================================
const SPREADSHEET_ID_2 = "1MjFQzFT4odUuXrgQNXFBPHPrbBveizzQiQnV3Xi3P4Q";
const SHEET_ID_2 = "1278003642";//DBTable

export interface ICoordcom2TableRequest {
    id: number,
    date: string, //0 -Позначка часу, 
    status: string, //15 - Статус: Затверджено/Відхилено
    rclub: string, //2 - Клуб
    name: string, //3 - Імя, Прізвище 
    aidtypy: string, //5 - Види допомоги: 
    check: string, //8 - Сума допомоги, грн
    note: string, //14 - Примітка
    subcommittee: string //minigrants
    destination: string, //Кому призначена допомога
    colorrow: string, //16 - color
    //other
    pArr?: string[],
};
export let selectedRowArr: number[];

export const loadSpreadsheet2 = async (): Promise<ICoordcom2TableRequest[]> => {
    selectedRowArr = [0, 15, 2, 3, 5, 8];
    try {
        const doc = new GoogleSpreadsheet(SPREADSHEET_ID_2);

        console.log("gs loadSpreadsheet");
        await doc.useServiceAccountAuth(creds);
        // loads document properties and worksheets
        await doc.loadInfo();
        const sheet = doc.sheetsById[SHEET_ID_2];

        let lastRow = sheet.rowCount/1;
        console.log("lastRow "+lastRow);
        await sheet.loadCells('A1:T'+lastRow); // loads a range of cells
        console.log(sheet.cellStats); // total cells, loaded, how many <non-empty></non-empty>
        //const rows = await sheet.getRows();

        let arr: ICoordcom2TableRequest[] = [];
        for (let r = 1; r < lastRow; r++) {
            const data = sheet.getCell(r, 0).value; //Ваш Ротарі Клуб, 
            if (data === null ) continue;

            let arr2: string[] = [];
            for (let r2 = 1; r2 < 16; r2++) {
                arr2.push(sheet.getCell(r, r2).value);
            }
            arr.push({
                id: r+1,
                date: sheet.getCell(r, 0).formattedValue, 
                status: sheet.getCell(r, 15).value, 
                rclub: sheet.getCell(r, 2).value, 
                name: sheet.getCell(r, 3).value, 
                aidtypy: sheet.getCell(r, 5).value,
                check: sheet.getCell(r, 8).value, 
                note: sheet.getCell(r, 14).value,
                subcommittee: 'minigrants',
                colorrow: sheet.getCell(r, 16).value,
                destination: "",
                pArr: arr2,                
            });
            //console.log("gs loadSpreadsheet- "+r+"]"+sheet.getCell(r, 1).value);
        }
        
        return arr;
    } catch (e) {
        console.error('Error LoadDocInfo: ', e);
        return [];
    }
};