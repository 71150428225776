import React, {useState, useRef, useEffect} from 'react';
import Box from '@mui/material/Box';

import TTHeader from '../TykoTables/TTHeader';
import { IUserProfile } from '../api/apiAdministration';

import IconForm from "../../assets/img/icons2/forms.png";
import IconAnalitics from "../../assets/img/icons2/analitics.png";

import CoordComForms from '../GuiComponents/CoordComForms';
import LeftMenuPanel from '../GuiComponents/LeftMenuPanel';

const userProfile:IUserProfile|null = null;

export default function D2232FormsPage() {
    const formList = ['forms','fmed', 'flog', 'ftech', 'fspec', 'fwash', 'fpeople', 'fcoordcom'];
    const furl = [
        '',
        'https://forms.gle/RE2SMqL3XsqL3j5i6',
        'https://forms.gle/Ny9js97ETNSanP8i6',
        '/techaidpage',
        'https://forms.gle/MwFGjvE8YXo5Cznk7',
        'https://forms.gle/JbiagpXq1tQeZeLr7',
        'https://forms.gle/XDaF5GdWFjJq1y5v5',
        'https://forms.gle/UyPLhXmqyRK9REhJ9',
    ]
    const mainFrameSize = useRef<HTMLDivElement>(null);
    const [currWidth, setCurWidth] = useState<number|undefined>(0);

    const [currPage, setCurPage] = useState<number>(0);

    const resizeHandler = () => {
        const { clientHeight, clientWidth } = mainFrameSize.current || {};
        setCurWidth(clientWidth);
      };
    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        resizeHandler();
        return () => {
            if (mainFrameSize && mainFrameSize.current)
                mainFrameSize.current.removeEventListener("resize", resizeHandler);
        };
    }, []);


    const onChangeMainPage = (page:string) => {
        const formIndex = formList.indexOf(page);
        window.location.href = furl[formIndex] ;
        //setCurPage(formIndex);
    }

    const onClickForm = (n:number) => {
        window.location.href = furl[n+1] ;
        //setCurPage(n+1);
    }

    const isVertical = currWidth ? currWidth > 700 : true;
    const direction = isVertical ? 'column' : 'row';
    const direction2 = !isVertical ? 'column' : 'row';
    return (
        <Box height="100%" display="flex" flexDirection="column" sx={{bgcolor: 'white', minHeight: "100%" }} >
            <TTHeader 
                title='Проєкти Дистрикту 2232' 
                userProfile={userProfile}
                language='Укр' 
                onSwitchLanguage={onSwitchLanguage} 
                pages={['1']}
                onSwitchPages={onSwitchPages}
                onLoginSuccess={onLoginSuccess}
                onLogoutSuccess={onLogoutSuccess}
                needEmailFilter={false}
                needTranslate={false}
            />
            <Box  height="100%" display="flex" flexDirection={direction2} sx={{}}>
                <LeftMenuPanel iconFirst={IconForm} onClickButton={onChangeMainPage} isVertical={isVertical} />
                
                <Box  width="100%" display="flex" flexDirection="column" sx={{}} >
                
                {//furl[currPage]
                }
                {currPage === 0 ?
                <CoordComForms onClick={onClickForm}/>
                :
                //<TykoForm tableId='minigrants' />
                <iframe src={furl[currPage]} width="99%" height="99%">Завантаження…</iframe>
                }
                </Box>
                
            </Box>
            {//<Footer />
            }
        </Box>
    );
}

//

const onSwitchLanguage = () => {
}
const onSwitchPages = (page:string) => {
}
const onLoginSuccess = (userData:IUserProfile) => {
}
const onLogoutSuccess = () => {
}