import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import Box,  { BoxProps } from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import TTHeader from '../TykoTables/TTHeader'
import ProjectCard2 from '../GUID2232Projects/ProjectCard2';
import { IUserProfile } from '../api/apiAdministration';

import { Container } from '@mui/system';

import Icon1 from "../../assets/img/icons/2039004.png";
import Icon2 from "../../assets/img/icons/2927516.png";
import Icon3 from "../../assets/img/icons/2621824.png";
import Icon4 from "../../assets/img/icons/2038999.png";

import IconTable from "../../assets/img/icons2/tables.png";
import IconForm from "../../assets/img/icons2/forms.png";

import IconMed from "../../assets/img/icons3/med.png";
import IconWinter from "../../assets/img/icons3/winter.png";
import IconLog from "../../assets/img/icons3/log.png";
import IconSpec from "../../assets/img/icons3/spec.png";
import IconWash from "../../assets/img/icons3/water.png";
import IconComm from "../../assets/img/icons3/comm3.png";
import IconPeople from "../../assets/img/icons3/people.png";

const userProfile:IUserProfile = {
    userName: 'tyko',
    email: 'tykogm7@gmail.com',
    imgUrl: '',
    committee: null,
}

function Item(props: BoxProps) {
    const { sx, ...other } = props;
    return (
      <Box
        sx={{
          p: 1,
          m: 1,
          /*bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
          color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
          border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
          ...sx,*/
        }}
        {...other}
      />
    );
  }
  const onClickEmpty = () => {

  }

export default function CoordComForms({onClick}:{onClick:(n:number)=>void}) {
    
    return (

        <Box  width="100%" display="flex" flexDirection="column" sx={{pt: '20px'}} >
        <Container >
            <Typography variant="h4" color="darkblue">Форми Д2232:</Typography>
            <Typography variant="h6" color="black">1. Оберіть напрямок(підкомітет), натисніть кнопку нижче</Typography>
            <Typography variant="h6" color="black">2. Заповніть форму, та відправте</Typography>
            <Typography variant="h6" color="black">3. Переглянути ваші запити зможете за <Link href="/myrequests" >лінком</Link></Typography>
            <Box width="100%" sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-evenly',
                        //p: 1,
                        //m: 1,
                        //bgcolor: 'background.paper',
                        //borderRadius: 1,
                        }}
            > 
                <Item>
                    <ProjectCard2 title='Медичний підкомітет' subtitle='медикаменти, медичне обладнання' desc='О.Палійчук' icon={IconMed} icon2={IconForm} onClick={()=>onClick(0)} />
                </Item>
                <Item>
                    <ProjectCard2 title='Логістичний підкомітет' subtitle='оплата перевезень, складських витрат' desc='Б.Боднар' icon={IconLog} icon2={IconForm} onClick={()=>onClick(1)} />
                </Item>
                <Item>
                    <ProjectCard2 title='Група Техдопомога (Зима)' subtitle='генератори, пічки, обігрівачі, ковдри, термобілизна' desc='О.Косьмина' icon={IconWinter} icon2={IconForm} onClick={()=>onClick(2)} />
                </Item>
                <Item>
                    <ProjectCard2 title='Спецтехніка' subtitle='на колесах' desc='В.Бондаренко' icon={IconSpec} icon2={IconForm} onClick={()=>onClick(3)} />
                </Item>
                <Item>
                    <ProjectCard2 title='WASH' subtitle='чиста вода, санітарія, гігієна' desc='М.Гітельман' icon={IconWash} icon2={IconForm} onClick={()=>onClick(4)} />
                </Item>
                <Item>
                    <ProjectCard2 title='Люди' subtitle='продукти, шелтери, ВПО, щойно звільнені н.п.' desc='С.Завадський' icon={IconPeople} icon2={IconForm} onClick={()=>onClick(5)} />
                </Item>
                <Item>
                    <ProjectCard2 title='Інше...' subtitle='всі інші запити, що не ввійшли до жодного з підкомітетів' desc='М.Стеблянко' icon={IconComm} icon2={IconForm} onClick={()=>onClick(6)} />
                </Item>
            </Box>
        </Container>
        </Box>
                
    );
}

const onSwitchLanguage = () => {
}
const onSwitchPages = (page:string) => {
}
const onLoginSuccess = (userData:IUserProfile) => {
}
const onLogoutSuccess = () => {
}