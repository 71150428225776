import React, {useState} from 'react';
import { useSearchParams } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import {apiLoadTykoTablesInfoList, ITykoTableListInfo} from './api/apiTykoTables';
import TykoTablePage from './TykoTablePage';
import CircularProgress from '@mui/material/CircularProgress';

import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import TykoFormStep from './TykoFormStep';
import {apiTykoTableAddRow, apiLoadTykoFormMeta, ITykoFormRow, ITykoFormStep, ITykoFormItem, ITykoFormMeta} from './api/apiTykoTableAddRow';

import RotaryIcon from "../../assets/img/menu/LOGO_Rotary_D2232.png";
import TykoIcon from "../../assets/img/TykoLogo.png";

const style = {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
};

export default function TykoForm({tableId}:{tableId:string}) {
  const LANG = 0;
    const [formMetaList, formMetaListSet] = useState<ITykoTableListInfo[] | []>([]);
    const [formMeta, formMetaSet] = useState<ITykoTableListInfo | null>(null);
    const [formMeta2, formMeta2Set] = useState<ITykoFormMeta | null>(null);
    const [formData, formDateSet] = useState<ITykoFormRow | null>(null);
    const [currStep, currStepSet] = useState<ITykoFormItem[] | []>([]);

    const loadMeta = async () => {
      const metaList: ITykoTableListInfo[] = await apiLoadTykoTablesInfoList();
      //console.log("gs loadMetaData arrsize="+metaList.length+"]");
      formMetaListSet(metaList);
      return metaList;
    };
    
    const loadMeta2 = async (sheetId:string, meta:ITykoTableListInfo[]) => {
      const filteredList: ITykoTableListInfo[] = filterGroupeList(sheetId, meta);
      //console.log("=============2 filteredList count="+filteredList.length);
      //const pages_:string[] = getSheetList(filteredList);
      //pagesSet(pages_);

      const info: ITykoTableListInfo | null = getITykoTableInfoFromList(filteredList, sheetId);
      formMetaSet(info);
      console.log("============= info"+(info?"true2":"false2"));

      const formMeta_: ITykoFormMeta | null = await apiLoadTykoFormMeta(sheetId);
      console.log("gs fetchMyAPI arrsize="+formMeta_ && formMeta_?.rowlist ?formMeta_?.rowlist.length:"-1");
      formMeta2Set(formMeta_);
      return formMeta_;
    };

    const loadFormData = async (sheetId: string, meta2: ITykoFormMeta) => {
      console.log("gs fetchMyAPI set formMeta"); 
      const formData_:ITykoFormRow = convertMetaToData(meta2);
      console.log("gs fetchMyAPI formData_ lenght="+formData_&&formData_.arr?formData_.arr.length:-1); 
      formDateSet(formData_);
    }

    const fetchMyAPI = React.useCallback(async () => {
      const meta:ITykoTableListInfo[] | [] = await loadMeta();
      const sheetId:string = findShetId(tableId, meta);
      const meta2:ITykoFormMeta | null = await loadMeta2(sheetId, meta);
      if (meta2) {
        const data = await loadFormData(sheetId, meta2);
      }
    }, []);

    React.useEffect(() => {
        fetchMyAPI();
    }, [fetchMyAPI]);

    const getGroupList = (list:ITykoTableListInfo[]) => {
      //console.log("============= getGroupList");
      const strArr:string[] = [];
      list.map((item) => {
          if (!strArr.includes(item.group)) {
              strArr.push(item.group);
              //console.log("============= getGroupList item="+item.group);
          }
      })
      return strArr;
  };
  const findShetId = (tableIdent:string, meta:ITykoTableListInfo[]) => {
    let correctedSheetIdent:string = "";

    const groupList:string[] = getGroupList(meta);
    if (groupList.includes(tableIdent)) {
        const reversed = [...meta].reverse();
        reversed.map((item) => {
            if (item.group === tableIdent) {
                correctedSheetIdent = item.tableId;
                console.log("============= initSheetId(group) correctedSheetIdent="+correctedSheetIdent);
            }
        })
    }
    else { //single sheet
        const reversed = [...meta].reverse();
        reversed.map((item) => {
            if (item.tableId === tableIdent) {
                correctedSheetIdent = tableIdent;
                console.log("============= initSheetId(single) correctedSheetIdent="+correctedSheetIdent);
            }
        })
    }
    
    return correctedSheetIdent;
  };
  //======================================================
  const getITykoTableInfoFromList = (list:ITykoTableListInfo[] | [], id:string) => {
    let sheetInfo: ITykoTableListInfo | null = null;
    console.log("getITykoTableInfoFromList size="+list.length);
    list.forEach((item) => {
        console.log("getITykoTableInfoFromList item.tableId="+item.tableId+", seachid="+id);
        if (item.tableId === id) {
            sheetInfo = item;
        }
    })
    return sheetInfo;
  };
    const convertMetaToData = (meta:ITykoFormMeta) => {
        const retArr:ITykoFormRow = {arr:[]};
        let currStep_:ITykoFormStep | null = null;
        meta.rowlist.map((item,index) => {
            if (item.rowType === 'step') {
                currStep_ = {
                    title: item.rowText_Lang[LANG],
                    arr: [],
                }
                retArr.arr.push(currStep_);
                //console.log("convert step="+item.rowText_Lang[LANG]);
            } else {
                const formItem:ITykoFormItem = {
                    rowName: item.rowText_Lang[LANG],
                    data: "",
                    type: item.rowType,
                    required: false,
                }
                //console.log("convert step="+item.rowText_Lang[LANG]);
                currStep_?.arr.push(formItem);
            }
        })
        return retArr;
    }
    const filterGroupeList = (sheetId:string, list:ITykoTableListInfo[]) => {
      const strArr:ITykoTableListInfo[] = [];
      const groupList:string[] = getGroupList(list);
      //console.log("============= groupList size="+groupList.length);
      //console.log("======== groupList 1="+groupList[0]);
      //console.log("======== groupList s="+sheetId);
      if (groupList.includes(sheetId)) {
          list.map((item) => {
              if (item.group === sheetId) {
                  strArr.push(item);
                  console.log("============= filterGroupeList(group) item="+item.tableId+", sheetId2="+sheetId);
              }
          })
      }
      else { //single sheet
          list.map((item) => {
              if (item.tableId === sheetId) {
                  strArr.push(item);
                  //sheetId2Set(sheetId);
                  console.log("============= filterGroupeList2(single) item="+item.tableId+", sheetId2="+sheetId);
              }
          })
      }
      
      return strArr;
    };
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
      [k: number]: boolean;
    }>({});
    const [contentStep, setContentStep] = React.useState(["Content 1", "Content 2", "Content 3"]);

    const totalSteps = () => {
      return formData?formData.arr.length:0;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted() && formData
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            formData.arr.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
      //currStepSet(formData?formData.arr[newActiveStep].arr[0]:null);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      //currStepSet(formData?formData.arr[(prevActiveStep) => prevActiveStep - 1]:null);
      //console.log("====== handleBack");
    };
  
    const handleStep = (step: number) => () => {
      setActiveStep(step);
      if (formData) {
        currStepSet([...formData.arr[step].arr]);
        console.log("====== handleStep");
      }
      
    };
  
    const handleComplete = () => {
      if (completedSteps() !== totalSteps() - 1) {
        console.log("====== Застосувати. completedSteps="+completedSteps()+", totalSteps="+totalSteps());
      } else {
        console.log("====== Відправити. completedSteps="+completedSteps()+", totalSteps="+totalSteps());
        if (formData)
            apiTykoTableAddRow(formData);
      }

      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
  
    const handleReset = () => {
      setActiveStep(0);
      setCompleted({});
    };

    const onChangeStep = (value:any, itemId:number, stepId:number) => {
        console.log("====== onChangeStep it="+itemId+", st="+stepId+", value="+value);

        if (formData) {
            //const retArr: ITykoFormItem[] = [...formData.arr[stepId].arr];
            const retArr: ITykoFormRow | null = formData;
            console.log("====== set formData="+JSON.stringify(formData.arr[stepId].arr[itemId]));
            formData.arr[stepId].arr[itemId].data = value;
            //retArr[itemId].data = value;
            console.log("====== set nformData="+JSON.stringify(formData.arr[stepId].arr[itemId]));
            console.log("====== set new value="+value);
            //formDateSet(retArr);
            currStepSet([...formData.arr[stepId].arr]);// важний костиль, без него не применяется стейт к formData
        }
    }

    return (
        <React.Fragment>
      <CssBaseline />
      <Box sx={{ width: '100%', minWidth: '540px', bgcolor: 'lightGray', height: '100vh', pt: '10px' }}>
      <Container maxWidth="sm">      
        <Paper elevation={5} sx={{ width: '100%', bgcolor: 'white', p: '20px' }}>
            <Grid container  sx={{ width: '100%', pl: '10px' }}>
                
                <Grid item sx={{pt: '10px', pr: '15px'}}><img src={RotaryIcon} height="60px" />
                </Grid>
                {formMeta ? 
                <Grid item sx={{pt: '10px'}}>
                    <Typography variant="h5" sx={{color: 'darkblue', fontWeight: 'bold'}}>{formMeta.menuTitles[LANG].title}</Typography>
                    <Typography variant="h6">{formMeta.menuTitles[LANG].desc}</Typography>
                </Grid> 
                : null}
                <Grid item sx={{ width: '10%'}}>
                </Grid>
                
            </Grid>
        {formData ? 

        <Box sx={{ width: '100%', height: '100%', pt: '20px' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {formData ? formData.arr.map((item, index) => (
          <Step key={index} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {item.title}
            </StepButton>
          </Step>
        )) : null}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              <div>All steps completed - you&apos;re finished</div>
              <div>Запит успішно доданий в базу під номером 1.</div>
              <div>Перейти до запиту в базі.</div>
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button variant="outlined" onClick={handleReset}>Reset</Button>
              <Button variant="outlined" onClick={handleReset}>Надіслати ще раз</Button>
              <Button variant="outlined" onClick={handleReset}>завершити</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                {
                    formData ? 
                    <TykoFormStep step={formData.arr[activeStep]} id={activeStep} onChange={onChangeStep} /> 
                    : "formData=null"
                }
                {/*JSON.stringify(formData?formData.arr[activeStep]:0)}<br/>
                =================<br/>
                {JSON.stringify(currStep?currStep:0)*/
                }

              {/*activeStep === 0 ? <TykoForm step={step1} id={0} onChange={onChangeStep} /> : null}
              {activeStep === 1 ? <TykoForm2 /> : null}
                {activeStep === 2 ? <TykoForm3 /> : null*/}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                variant="outlined"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Назад
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button variant="outlined" onClick={handleNext} sx={{ mr: 1 }}>
                Далі
              </Button>
              {activeStep !== totalSteps() &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Крок {activeStep + 1} затверджений
                  </Typography>
                ) : (
                  <Button variant="outlined" onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Надіслати'
                      : 'Затвердити дані'}
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
      </Box> : <CircularProgress />}
      
    </Paper>
    <Box sx={{pt: '10px'}}>
      <Typography variant="caption">Для реестрації запитів використовуються Тіко-форми.</Typography><br/>
      <Typography variant="caption">Якщо вам всеж зручніше використовувати Гугл-форми, перейдіть за посиланням <Link href={formMeta?.formLink} variant="caption">гугл версія</Link>. </Typography> 
    </Box>
    </Container>
    </Box>
    </React.Fragment>
    );
}
