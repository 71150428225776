import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';

import {ITykoTableData, ITykoRows} from './api/apiTykoTables';
import FileIcon from "../../assets/img/file-icon.png";
import {sendEmail} from './api/sendEmail';
import {apiGSUpdateCell} from './api/apiGSUpdateCell';

const IDButton = styled(Button)({
  backgroundColor: 'lightblue',
  '&:hover': {
      backgroundColor: 'white',
    },
});

export default function GSTykoTable({arr, colList, rowClicked, moreTestClicked, moreClicked, adminProfile}:
          {arr:ITykoTableData[] | [], colList:string[], 
            rowClicked: (index: number) => void, 
            moreTestClicked: (index: number, item: ITykoTableData) => void, 
            moreClicked: (index: number, item: ITykoTableData) => void,
            adminProfile:boolean}) 
{
  const firstHeader = 'Button info';
  const SityHeader = 'Місто';
  const DateHeader = 'Дата';
  const SityHeaderAng = 'Sity';
  const DateHeaderAng = 'Date'; 
  const PowerHeader = 'Потужність';
  const PowerHeaderAng = 'Power';
  const [orderBy, setOrderBy] = useState(firstHeader);
  const [order, setOrder] = useState<'asc'|'desc'>('desc');

  const onClickSend = async (id:string, email: string, user_name:string|undefined, status:string|undefined, short_desc:string|undefined, email_comment:string|undefined, subcommittee:string|undefined) => {
    let send_to:string = email+', ';
    send_to += 'leskovit@gmail.com, ';
    send_to += subcommittee==='логістика'?'borysbodnar@gmail.com,':'';
    send_to += subcommittee==='спецтехніка'?'volbondarenko@gmail.com,':'';

    //sendEmail(id, send_to, user_name, status, short_desc, email_comment);
    //await apiGSUpdateCell('1CRB75TYW4nClwFeEMJd_rkIKgEVXroySUAG2fmj5m6Q', '540110862', 'sended', 1);
    //needUpdateData();
    alert("Лист відправлено. Перевірте вашу поту."+send_to)
  }

  const handleSort = (property:any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const filteredTableData = arr;/*.sort((a, b) => {
    const sortColumn = colList.indexOf(orderBy);

    switch(orderBy) {
      case SityHeader:
      case SityHeaderAng:
        
        if (order === "asc") {
          const aValue = typeof a.data[sortColumn] === "string" ? a.data[sortColumn].toString() : a.data[sortColumn];
          const bValue = typeof b.data[sortColumn] === "string" ? b.data[sortColumn].toString() : b.data[sortColumn];
          return aValue && typeof a.data[sortColumn] === "string"? aValue.localeCompare(bValue) : 0;
        } else {
          const aValue = typeof a.data[sortColumn] === "string" ? a.data[sortColumn].toString() : a.data[sortColumn];
          const bValue = typeof b.data[sortColumn] === "string" ? b.data[sortColumn].toString() : b.data[sortColumn];
          return bValue && typeof b.data[sortColumn] === "string" ? bValue.localeCompare(aValue) : 0;
        }
        break;
      case PowerHeader:
      case PowerHeaderAng:
        const aValue = a.data[sortColumn];
        const bValue = b.data[sortColumn];
        
        if (order === "asc") {
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            const asValue = typeof aValue === "string" ? aValue.toString() : aValue;
            const bsValue = typeof bValue === "string" ? bValue.toString() : bValue;
            return asValue && typeof aValue === "string"? asValue.localeCompare(bsValue) : -1;
          }
          if (typeof aValue === 'string' || !aValue) {
            return 1;
          }
          if (typeof bValue === 'string' || !bValue) {
            return -1;
          }
          return aValue < bValue ? -1 : 1;
        } else {
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            const asValue = typeof aValue === "string" ? aValue.toString() : aValue;
            const bsValue = typeof bValue === "string" ? bValue.toString() : bValue;
            return bsValue && typeof bValue === "string" ? bsValue.localeCompare(asValue) : -1;
          }
          if (typeof aValue === 'string' || !aValue) {
            return 1;
          }
          if (typeof bValue === 'string' || !bValue) {
            return -1;
          }
          return aValue < bValue ? 1 : -1;
        }
        break;
      case DateHeader:
      case DateHeaderAng:
        if (order === "asc") {
          return a.date < b.date ? -1 : 1;
        } else {
          return a.date < b.date ? 1 : -1;
        }
        break;
      case firstHeader:
        if (order === "asc") {
          return a.id < b.id ? -1 : 1;
        } else {
          return a.id < b.id ? 1 : -1;
        }
        break;
      default:
        // do nothing
        return 0;
    }
  });*/

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 330 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ bgcolor: blue[100]}} >
            <TableCell key={firstHeader}>
              {/*<TableSortLabel active={orderBy === firstHeader} direction={order} onClick={() => handleSort(firstHeader)}>
              <Typography fontWeight="bold">
              {firstHeader}
              </Typography>
                </TableSortLabel>*/}
                {firstHeader}
            </TableCell>
            {adminProfile ? 
            <TableCell>Admin</TableCell> : null
            }
            {
              colList ? 
              colList.map((header,index) =>
                <TableCell key={header}>
                  {header===SityHeader || header===SityHeaderAng || 
                   header===DateHeader || header===DateHeaderAng || 
                   header===PowerHeader || header===PowerHeaderAng ? 
                    <TableSortLabel active={orderBy === header} direction={order} onClick={() => handleSort(header)}>
                    <Typography fontWeight="bold">
                    {header}
                    </Typography>
                    </TableSortLabel>
                  : header }
                </TableCell>
              ) : null
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTableData.map((row,index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{ backgroundColor: row.color ? row.color : "white" }}
            >
              <TableCell key={index} component="th" scope="row" sx={{ paddingLeft: 1, paddingRight: 0, minWidth: 80, }} >
                
                {row.id <= 2 && row.data && row.data[0] === "Total:" ?
                null : 
                <div>
                <IDButton   style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} 
                  size="small" 
                  variant="outlined" 
                  onClick={() => rowClicked(index)}>{row.id}
                </IDButton>
                {row.haveFile ? <img style={{margin: '-4px'}} src={FileIcon} height="20px" /> : null
                }
                
                {/*!row.sendEmail?
                <Tooltip title="Відправити повідомлення до голови Координаційного підкомітету">
                    <IDButton   style={{ maxHeight: '30px', minWidth: '30px', minHeight: '30px', marginLeft: '15px'}} 
                    size="small" 
                    variant="outlined" 
                    onClick={()=>onClickSend(row.id.toString(), row.email, row.user_name, row.status, row.short_desc, row.email_comment, row.subcommittee)}>Send
                  </IDButton>
              </Tooltip>
                <IconButton color='success'>
                  <EmailIcon onClick={()=>onClickSend(row.id.toString(), row.email, row.user_name, row.status, row.short_desc, row.email_comment, row.subcommittee)} />
                </IconButton>
:null*/}
                </div>
                }
                
                
              </TableCell>
              {adminProfile ? 
              <>
              <IconButton color="secondary" aria-label="add an alarm" onClick={() => moreTestClicked(index, row)} >
                  <Tooltip title={!row.sendEmail?"need send":"sended"}>
                    <MoreVertIcon />
                  </Tooltip>
              </IconButton> 
              <IconButton color="secondary" aria-label="add an alarm" onClick={() => moreClicked(index, row)} >
                  <Tooltip title={!row.sendEmail?"need send":"sended"}>
                    <EmailIcon />
                  </Tooltip>
              </IconButton> 
              </> : null
              }
              {
                row.data.map((item, index) => 
                  <TableCell key={index}>{item}</TableCell>
                )
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
