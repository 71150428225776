import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import {ITableRequest, ICoordcom2TableRequest} from "../api/google-spreadsheet/apiCoordComTable"
import { Typography } from '@mui/material';

const IDButton = styled(Button)({
  backgroundColor: 'lightblue',
  '&:hover': {
      backgroundColor: 'white',
    },
});

function createData(
  name: string,
  calories: string,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}



export default function GSTable({arr, rowClicked}:{arr: (ITableRequest | ICoordcom2TableRequest)[] | [], rowClicked: (index: number) => void}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 330 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="left">Статус</TableCell>
            <TableCell align="left">Імя, Прізвище</TableCell>
            <TableCell align="left">Клуб</TableCell>
            <TableCell align="left">Види допомоги:</TableCell>
            <TableCell align="left">Кому призначено</TableCell>
            <TableCell align="left">Сума, грн</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        
          {arr.map((row,index) => (
            <TableRow
              key={arr.length - index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{ backgroundColor: row.colorrow ? row.colorrow : "white" }}
            >
              <TableCell component="th" scope="row"><IDButton  style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} 
                size="small" variant="outlined" onClick={() => rowClicked(index)}>
                {arr.length - index + 1}</IDButton></TableCell>
              <TableCell align="left"><Typography variant="body1">{row.status}</Typography><Typography variant="caption">{row.date}</Typography></TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{row.rclub}</TableCell>
              <TableCell align="left">{row.aidtypy}</TableCell>
              <TableCell align="left">{row.destination}</TableCell>
              <TableCell align="left">{row.check}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
