import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import {ITableRequest} from "../api/google-spreadsheet/apiCoordComTable"

export default function GSTMedDetailsPage2({arrItem, open, onClose}:{arrItem:ITableRequest|null, open: number, onClose: () => void}) {
    
    const fixGoogleLink = (link: string) => {
        const newlink = link ? link.replace("https://drive.google.com/open?id=", "https://drive.google.com/file/d/") : null;
        return newlink ? newlink.replace("/view?usp=sharing", "")+"/preview" : "";
    };

    //const newlink = arrItem && arrItem.file ? arrItem.file.replace("https://drive.google.com/open?id=", "https://drive.google.com/file/d/") : null;
    //const newlink2 = newlink ? newlink.replace("/view?usp=sharing", "")+"/preview" : null;

    //https://drive.google.com/open?id=1ppwitN8jnb3OPVcEWVgzHLe6688GPiuG
    //<iframe src="https://drive.google.com/file/d/1ppwitN8jnb3OPVcEWVgzHLe6688GPiuG/preview" width="640" height="480" allow="autoplay"></iframe>
    const newlink = arrItem ? fixGoogleLink(arrItem.file) : "";
    const files2 = arrItem && arrItem.files ? arrItem.files.split(', ') : null;

    return (
        <Dialog onClose={onClose} open={open!=-1} >
        <DialogTitle> Деталі Запиту <Button variant="outlined" onClick={onClose}>Назад</Button></DialogTitle>
        {arrItem ? 
        <Paper style={{padding: '20px'}}>
            <div><Typography style={{ backgroundColor: "WhiteSmoke" }} variant="h6">ID:</Typography> <Typography variant="body2">{arrItem.id?arrItem.id:"-"}</Typography></div>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Дата: </Typography><Typography variant="body2">{arrItem.date?arrItem.date:"-"}</Typography>
            <Typography  style={{ backgroundColor: arrItem.colorrow ? arrItem.colorrow : "WhiteSmoke", marginTop: "10px" }} variant="h6">Статус: </Typography><Typography style={{ backgroundColor: arrItem.colorrow ? arrItem.colorrow : "transparent"}} variant="body2">{arrItem.status?arrItem.status:"-"}</Typography>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Імя, Прізвище: </Typography><Typography variant="body2">{arrItem.name?arrItem.name:"-"}</Typography>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Клуб: </Typography><Typography variant="body2">{arrItem.rclub?arrItem.rclub:"-"}</Typography>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Види допомоги: : </Typography><Typography variant="body2">{arrItem.aidtypy?arrItem.aidtypy:"-"}</Typography>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Кому призначена допомога: </Typography><Typography variant="subtitle2">{arrItem.destination?arrItem.destination:"-"}</Typography>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Сума допомоги, грн: </Typography><Typography variant="body2">{arrItem.check?arrItem.check:"-"}</Typography>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Короткий опис запиту: </Typography><Typography variant="body2">{arrItem.desc?arrItem.desc:"-"}</Typography>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Повний опис запиту: </Typography><Typography variant="body2">{arrItem.longdesc?arrItem.longdesc:"-"}</Typography>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Примітка: </Typography><Typography variant="body2">{arrItem.note?arrItem.note:"-"}</Typography>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Електронна адреса: </Typography><Typography variant="body2">{arrItem.email?arrItem.email:"-"}</Typography>
            <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">Телефон: </Typography><Typography variant="body2">{arrItem.phone?arrItem.phone:"-"}</Typography>
            
            <Typography  style={{ backgroundColor: "WhiteSmoke" }} variant="h6">Додати файл запиту: </Typography><Typography variant="body2">{arrItem.file}</Typography>
            {newlink ? <div><iframe src={newlink} width="100%" height="500" allow="autoplay"></iframe></div> : null}
            <Typography  style={{ backgroundColor: "WhiteSmoke" }} variant="h6">Інші файли запиту: </Typography>
            {
                files2 ? files2.map((itemlink,index) => (
                    <div>
                        {itemlink}
                        {itemlink ? <div><iframe src={fixGoogleLink(itemlink)} width="100%" height="500" allow="autoplay"></iframe></div> : null}
                    </div>
                )) : "-"
            }
        </Paper>
        
        : null}
        </Dialog>
    )
};
