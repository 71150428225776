import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import {IMedRequest, loadSpreadsheetFast} from "../api/google-spreadsheet/apiMedTable"
import { Typography } from '@mui/material';

const IDButton = styled(Button)({
  backgroundColor: 'lightblue',
  '&:hover': {
      backgroundColor: 'white',
    },
});

function createData(
  name: string,
  calories: string,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}



export default function GSTable({arrg, hasSityFilter, hasTypeFilter, rowClicked}:{arrg:IMedRequest[], rowClicked: (index: number) => void, hasSityFilter: boolean, hasTypeFilter: boolean}) {
     
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 330 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="left">{hasSityFilter?<Typography variant="body2" color="red">[є фільтр]</Typography>:null}Місто</TableCell>
            <TableCell align="left">Назва запиту</TableCell>
            <TableCell align="left">{hasTypeFilter?<Typography variant="body2" color="red">[є фільтр]</Typography>:null}Тип</TableCell>
            <TableCell align="left">К-сть</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {arrg.map((row,index) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row"><IDButton  style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} size="small" variant="outlined" onClick={() => rowClicked(index)}>{row.id}</IDButton></TableCell>
              <TableCell align="left"><Typography variant="body1">{row.sity}</Typography><Typography variant="caption">{row.date}</Typography></TableCell>
              <TableCell align="left">{row.title}</TableCell>
              <TableCell align="left">{row.type}</TableCell>
              <TableCell align="left">{row.num}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
