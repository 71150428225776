import React, {useEffect, useState} from 'react';
// @ts-ignore
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'

import {ITableRequest, loadSpreadsheet} from "../api/google-spreadsheet/apiCoordComTable";
import {ICoordcom2TableRequest, loadSpreadsheet2} from "../api/google-spreadsheet/apiCoordComTable"

import GSTable2 from "../mycomponents/GSTable2"
import MedFiltersPanel, {IMedTypeFilters} from "../mycomponents/MedFiltersPanel"
import GSTMedDetailsPage2 from "./GSTMedDetailsPage2"
import {useLocalStorage} from "../mycomponents/useLocalStorage"

//import Typography from '@mui/material/Typography';
const Item = styled(Paper)(({ theme }) => ({
    //backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    //...theme.typography.body2,
    //padding: theme.spacing(1),
    //textAlign: 'center',
    //color: theme.palette.text.secondary,
  }));

export default function CoordComTablePage() {
    const [arrgFiltered, arrgFilteredSet] = useState<ITableRequest[] | []>([]);
    const [arrg2, arrg2Set] = useState<ICoordcom2TableRequest[] | []>([]);
    const [openDetailsDialogIndex, openDetailsDialogIndexSet] = React.useState(-1);

    const fetchMyAPI = React.useCallback(async () => {
        const array: ITableRequest[] = await loadSpreadsheet();
        const revarr: ITableRequest[] = array.map(item => item).reverse();

        const array2: ICoordcom2TableRequest[] = await loadSpreadsheet2();

        arrgFilteredSet(revarr);
        arrg2Set(array2.reverse());
    }, []);

    React.useEffect(() => {
        fetchMyAPI();
    }, [fetchMyAPI]);

    const handleDetilsDialogClose = () => {
        openDetailsDialogIndexSet(-1);
    };

    const rowTableClicked = (index: number) => {
        openDetailsDialogIndexSet(index);
    }

    const combinedArray: (ITableRequest | ICoordcom2TableRequest)[] = [...arrgFiltered, ...arrg2];

    combinedArray.sort((a, b) => {
        const dateA = new Date(a.date.replace(/(\d{2}).(\d{2}).(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6')).getTime();
        const dateB = new Date(b.date.replace(/(\d{2}).(\d{2}).(\d{4}) (\d{2}):(\d{2}):(\d{2})/, '$3-$2-$1T$4:$5:$6')).getTime();
              console.log("============ "+dateA+" / "+a.name);
        return dateA - dateB;
      });

    return (
        <TableContainer component={Paper}>
            <Typography variant="h3" gutterBottom>База даних: Запити Координаційного Комітету</Typography>
            <Typography variant="h5" gutterBottom>Координаційний Комітет подолання гуманітарної катастрофи</Typography>

            <br/>
            <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {/*<Grid>
                <Item>
                <Paper sx={{ width: 330, overflow: 'auto' }}>
                    фільтри
                </Paper>
                </Item>
                </Grid>*/}
                <Grid item xs>
                <Item>
                { (combinedArray && combinedArray.length) ?
                    <GSTable2 arr={combinedArray.reverse()} rowClicked={rowTableClicked} />
                    :
                    <CircularProgress />
                }
                </Item>
                </Grid>
            </Grid>
            </Box>
            {<GSTMedDetailsPage2 
                arrItem={arrgFiltered && openDetailsDialogIndex>=0? arrgFiltered[openDetailsDialogIndex] : null} 
                open={openDetailsDialogIndex}
                onClose={handleDetilsDialogClose}
            />}
            
            
        </TableContainer>
        
    );
}