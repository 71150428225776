import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import {IMedRequest, loadSpreadsheetFast} from "../api/google-spreadsheet/apiMedTable"

export default function GSTMedDetailsPage({arrItem, open, onClose}:{arrItem:IMedRequest|null, open: number, onClose: () => void}) {
    
    const newlink = arrItem ? arrItem.url.replace("https://drive.google.com/open?id=", "https://drive.google.com/file/d/") : null;
    const newlink2 = newlink ? newlink.replace("/view?usp=sharing", "")+"/preview" : null;
    //https://drive.google.com/open?id=1ppwitN8jnb3OPVcEWVgzHLe6688GPiuG
    //<iframe src="https://drive.google.com/file/d/1ppwitN8jnb3OPVcEWVgzHLe6688GPiuG/preview" width="640" height="480" allow="autoplay"></iframe>
    return (
        <Dialog onClose={onClose} open={open!=-1}>
        <DialogTitle> Деталі Запиту <Button variant="outlined" onClick={onClose}>Назад</Button></DialogTitle>
        {arrItem ? 
        
        <Paper >
            <div><Typography variant="h6">ID:</Typography> <Typography variant="body2">{arrItem.id}</Typography></div>
            <Typography variant="h6">Дата: </Typography><Typography variant="body2">{arrItem.date}</Typography>
            <Typography variant="h6">Місто: </Typography><Typography variant="body2">{arrItem.sity}</Typography>
            <Typography variant="h6">Ім'я, Прізвище контактної особи: </Typography><Typography variant="body2">{arrItem.name}</Typography>
            <Typography variant="h6">Назва запиту: </Typography><Typography variant="body2">{arrItem.title}</Typography>
            <Typography variant="h6">Додати Додатки Запиту: </Typography><Typography variant="body2">{arrItem.urlAdv}</Typography>
            <Typography variant="h6">Класифікатор оптових закупівель медичного обладнання: </Typography><Typography variant="subtitle2">{arrItem.type}</Typography>
            <Typography variant="h6">Примітки: </Typography><Typography variant="body2">{arrItem.desc}</Typography>
            <Typography variant="h6">Електронна адреса: </Typography><Typography variant="body2">{arrItem.num8}</Typography>
            <Typography variant="h6">Кількість в запиті: </Typography><Typography variant="body2">{arrItem.num}</Typography>
            <Typography variant="h6">Класифікатор медичних витратних матеріалів: </Typography><Typography variant="body2">{arrItem.type2}</Typography>
            <Typography variant="h6">Номер відділення Нової Пошти, місто, адреса, ПІП, телефон: </Typography><Typography variant="body2">{arrItem.num11}</Typography>
            <Typography variant="h6">Прізвище, ім'я: </Typography><Typography variant="body2">{arrItem.num12}</Typography>
            <Typography variant="h6">Обґрунтування запиту (коротко): </Typography><Typography variant="body2">{arrItem.num13}</Typography>
            <Typography variant="h6">Додаткові пояснення: </Typography><Typography variant="body2">{arrItem.desc2}</Typography>
            <Typography variant="h6">Наявність конфлікту інтересів у ротарійців-учасників проекту: </Typography><Typography variant="body2">{arrItem.num15}</Typography>
            <Typography variant="h6">Телефон: </Typography><Typography variant="body2">{arrItem.num16}</Typography>
            <Typography variant="h6">ПРОДАВЕЦЬ ОБЛАДНАННЯ: </Typography><Typography variant="body2">{arrItem.num17}</Typography>
            <Typography variant="h6">Комерційну пропозиція продавця: </Typography><Typography variant="body2">{arrItem.urlKom}</Typography>

            <Typography variant="h6">Додати файл запиту: </Typography><Typography variant="body2">{arrItem.url}</Typography>
            {newlink2 ? <div><iframe src={newlink2} width="100%" height="500" allow="autoplay"></iframe></div> : null}
        </Paper>
        
        : null}
        </Dialog>
    )
};
