
import React, {useState} from 'react';
import emailjs from '@emailjs/browser';

const onSendEmail = () => {
    var templateParams = {
        user_name: 'James',
        id: '111',
        short_desc: 'my message',
        status: 'status text',
        adv_message: 'adv message',
    };
    emailjs.init("7mKMPxB_RlU16SESz");
    emailjs.send('service_7ffs245', 'template_51ag7z9', templateParams)
        .then(function(response) {
           console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
           console.log('FAILED...', error);
        });
}

export default function TestEmailPage() {
    
    return (
        <div>
            <button onClick={onSendEmail}>send test email</button>
        </div>
    );
}
