import React, {useState} from 'react';
import { useSearchParams } from "react-router-dom";

import TykoForm from './TykoForm';

export default function TykoFormss() {
    const [sheetList, sheetListSet] = useState<string[] | []>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const tableName = searchParams.get('n');
    const isDebug:boolean = searchParams.get('debug') === "true";

    return (
        <div>
            <div>
                <div>
                {sheetList && tableName ? 
                <TykoForm tableId={tableName} /> : "error: tableName1 null"}
                </div>
                
            </div>
        </div>
    );
}
