import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function TykoTableDetailsPage({arrItem, colList, open, onClose}:{arrItem:string[]|[], colList:string[]|[], open: number, onClose: () => void}) {
    
    const fixGoogleLink = (link: string) => {
        const newlink = link ? link.replace("https://drive.google.com/open?id=", "https://drive.google.com/file/d/") : null;
        return newlink ? newlink.replace("/view?usp=sharing", "")+"/preview" : "";
    };

    const showFiles = (files:string) => {
        const filesArr = files ? files.split(', ') : null;
        console.log("showFiles files="+files+", size="+filesArr?.length);

        return (
            filesArr ? filesArr.map((itemlink,index) => (
                <div>
                    {itemlink}
                    {itemlink ? <div><iframe src={fixGoogleLink(itemlink)} width="100%" height="500" allow="autoplay"></iframe></div> : null}
                </div>
            )) : <>"-"</>
        )
        
    };


    //const newlink = arrItem && arrItem.file ? arrItem.file.replace("https://drive.google.com/open?id=", "https://drive.google.com/file/d/") : null;
    //const newlink2 = newlink ? newlink.replace("/view?usp=sharing", "")+"/preview" : null;

    //https://drive.google.com/open?id=1ppwitN8jnb3OPVcEWVgzHLe6688GPiuG
    //<iframe src="https://drive.google.com/file/d/1ppwitN8jnb3OPVcEWVgzHLe6688GPiuG/preview" width="640" height="480" allow="autoplay"></iframe>
    
    /////const newlink = arrItem ? fixGoogleLink(arrItem.file) : "";
    /////const files2 = arrItem && arrItem.files ? arrItem.files.split(', ') : null;

    return (
        <Dialog onClose={onClose} open={open!=-1} >
        <DialogTitle> Request information <Button variant="outlined" onClick={onClose}>Назад</Button></DialogTitle>
        {arrItem ? 
        <Paper style={{padding: '20px'}}>
            {
                arrItem.map((item,index) => 
                <div key={index}>
                    <Typography  style={{ backgroundColor: "WhiteSmoke", marginTop: "10px" }} variant="h6">{colList[index]} </Typography>
                    
                    {item && typeof item === 'string' && item.startsWith("http") ? 
                        showFiles(item)
                        : <Typography variant="body2">{item?item:"-"}</Typography>
                    }
                    
                </div>
                )
            }
            
        </Paper>
        
        : null}
        </Dialog>
    )
};
