import React, {useState} from 'react';
import Box,  { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';

import { IUserProfile } from '../api/apiAdministration';
import ProjectCard2 from '../GUID2232Projects/ProjectCard2';
import IconTable from "../../assets/img/icons2/tables.png";

import IconMed from "../../assets/img/icons3/med.png";
import IconWinter from "../../assets/img/icons3/winter.png";
import IconLog from "../../assets/img/icons3/log.png";
import IconSpec from "../../assets/img/icons3/spec.png";
import IconWash from "../../assets/img/icons3/water.png";
import IconComm from "../../assets/img/icons3/comm3.png";
import IconPeople from "../../assets/img/icons3/people.png";

function Item(props: BoxProps) {
    const { sx, ...other } = props;
    return (
      <Box
        sx={{
          p: 1,
          m: 1,
          /*bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
          color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
          border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
          ...sx,*/
        }}
        {...other}
      />
    );
  }
const onClickEmpty = () => {

}

export default function CoordComTables({userProfile, onClick}:{userProfile:IUserProfile| null, onClick:(n:number)=>void}) {
    
    return (

        <Box  width="100%" display="flex" flexDirection="column" sx={{pt: '20px'}} >
        <Container >
            <Typography variant="h4" color="darkblue">Мої запити</Typography>
            <Typography variant="h6" color="darkblue">Всі запити, що зареєстровані на "{userProfile?.email}"</Typography>
            <Box width="100%" sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-evenly',
                        //p: 1,
                        //m: 1,
                        //bgcolor: 'background.paper',
                        //borderRadius: 1,
                        }}
            >
        
                <Item>
                    <ProjectCard2 title='Медичний підкомітет' subtitle='медикаменти, медичне обладнання' desc='А.Кожина' icon={IconMed} icon2={IconTable} onClick={()=>onClick(0)} />
                </Item>
                <Item>
                    <ProjectCard2 title='Логістичний підкомітет' subtitle='оплата перевезень, складських витрат' desc='Б.Боднар' icon={IconLog} icon2={IconTable} onClick={()=>onClick(1)} />
                </Item>
                {/*<Item>
                    <ProjectCard2 title='Група Техдопомога (Зима)' subtitle='генератори, пічки, обігрівачі, ковдри, термобілизна' desc='О.Косьмина' icon={IconWinter} icon2={IconTable} onClick={()=>onClick(2)} />
                    </Item>*/}
                <Item>
                    <ProjectCard2 title='Спецтехніка' subtitle='на колесах' desc='В.Бондаренко' icon={IconSpec} icon2={IconTable} onClick={()=>onClick(3)} />
                </Item>
                {/*<Item>
                    <ProjectCard2 title='WASH' subtitle='чиста вода, санітарія, гігієна' desc='М.Гітельман' icon={IconWash} icon2={IconTable} onClick={()=>onClick(4)} />
                </Item>
                <Item>
                    <ProjectCard2 title='Люди' subtitle='продукти, шелтери, ВПО, щойно звільнені н.п.' desc='С.Завадський' icon={IconPeople} icon2={IconTable} onClick={()=>onClick(5)} />
                </Item>
                <Item>
                    <ProjectCard2 title='Інше...' subtitle='всі інші запити, що не ввійшли до жодного з підкомітетів' desc='М.Стеблянко' icon={IconComm} icon2={IconTable} onClick={()=>onClick(6)} />
                </Item>*/}
            </Box>
        </Container>
        </Box>
    );
}

const onSwitchLanguage = () => {
}
const onSwitchPages = (page:string) => {
}
const onLoginSuccess = (userData:IUserProfile) => {
}
const onLogoutSuccess = () => {
}