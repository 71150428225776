import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {ITykoTableData, ITykoRows} from '../TykoTables/api/apiTykoTables';
import FileIcon from "../../assets/img/file-icon.png";
import { blue } from '@mui/material/colors';

import {sendEmail} from './api/sendEmail';
import {apiGSUpdateCell} from './api/apiGSUpdateCell';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';

const IDButton = styled(Button)({
  backgroundColor: 'lightblue',
  '&:hover': {
      backgroundColor: 'white',
    },
});

export default function GSTykoTableV2({arr, colList, rowClicked, needUpdateData, needRating, cbRating}:
  {arr:ITykoTableData[] | [], colList:string[]|[], rowClicked: (index: number) => void, needUpdateData:()=>void, needRating:boolean, cbRating: (val: number, row: number) => void|undefined}) {
  //const [value, setValue] = React.useState<number | null>(3);
  
  const onClickSetStatus = (id:number) => {
    alert('Ця функція ще в розробці. Можна буде змінити статус запиту з "зареєстровано" на "затверджено" або "виконано"' )
      //apiSetStatus(id, 'розглянуто');
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 330 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ bgcolor: blue[100]}} >
            <TableCell>Button info</TableCell>
            {
              colList ? 
              colList.map((item,index) =>
                <TableCell key={index}>{item}</TableCell>
              ) : null
            }
            {
              //<TableCell>Змінити статус</TableCell>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {arr.map((row,index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{ backgroundColor: row.color ? row.color : "white" }}
            >
              <TableCell key={index} component="th" scope="row">
                
                {row.id <= 2 && row.data && row.data[0] === "Total:" ?
                null : 
                <div>
                <IDButton   style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} 
                  size="small" 
                  variant="outlined" 
                  onClick={() => rowClicked(index)}>{row.id}
                </IDButton>
                
                {row.haveFile ? <img style={{margin: '-4px'}} src={FileIcon} height="20px" /> : null
                }
                
                </div>
                }
                
                {needRating ?
                <Rating
                  name="simple-controlled"
                  size="small"
                  
                  value={ row.rating ? row.rating : 3 }
                  onChange={(event, newValue) => {
                    if (newValue)
                      cbRating(newValue,row.id); 
                    
                  }}
                /> : null
                }
                
              </TableCell>
              {
                row.data.map((item) => 
                  <TableCell>{item}</TableCell>
                )
              }
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
