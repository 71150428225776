// @ts-ignore
import {GoogleSpreadsheet} from "google-spreadsheet";
import creds from "../../../api/google-spreadsheet/med_credentials.json";

export const SPREADSHEET_ID = "1JT1lxAWy6JUFAacLEQ7vUCVNiKQ8XoEhW6QJ2ehBibo";
const SHEET_ID = "0";//DBTable

export interface ITykoRows {
    rowNumber: string,
    rowText_Lang: string[],
    rowType: string,
};

export interface ITykoTableListInfo {
    tableId: string,
    tableIndex: string,
    sheetIndex: string,
    menuTitles: IMenuTitles[],
    group: string,
    formLink: string,
    readAccess: string,
};
export interface IMenuTitles {
    title: string,
    desc: string,
};

export interface ITykoTableInfo {
    tableName: string,//delete
    url: string,
    rowlist: ITykoRows[],
    advRowlist: ITykoRows[],
};

export interface ITykoTableData {
    id: number,
    email: string,
    color: string,
    haveFile: boolean,
    data: string[],
    advData: string[],
    rowlist: ITykoRows[],
    advRowlist: ITykoRows[],

    sendEmail: boolean,
    status?: string,
    user_name?: string,
    short_desc?: string,
    email_comment?: string,
    subcommittee?: string,
    date: string,
    rating: number,
};

export interface IStatusFilter {
    status: string,
    color: string,
    cheked: boolean,
};
export interface IStatusFilterColumn {
    tableId: string,
    column: string,
    title: string,
    filters: IStatusFilter[],
};

export const apiLoadTykoTableInfoData = async (sheetName:string): Promise<ITykoTableInfo | null> => {
    let ret: ITykoTableInfo | null = null;
    try {
        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        console.log("gs loadSpreadsheet info");
        await doc.useServiceAccountAuth(creds);
        // loads document properties and worksheets
        await doc.loadInfo();
        const sheet = doc.sheetsByTitle[sheetName];

        let lastColumn = sheet.rowCount/1;
        console.log("lastRow "+lastColumn);
        await sheet.loadCells('A1:L'+lastColumn); // loads a range of cells
        console.log(sheet.cellStats); // total cells, loaded, how many <non-empty></non-empty>
        //const rows = await sheet.getRows();

        
        let arr: ITykoRows[] = [];
        for (let r = 2; r < lastColumn; r++) {
            const data = sheet.getCell(r, 3).value; //номер колонки
            if (data === null ) continue;

            arr.push({
                rowNumber: data,
                rowText_Lang: [sheet.getCell(r, 4).value, sheet.getCell(r, 6).value], 
                rowType: sheet.getCell(r, 5).value,
            });
            //console.log("gs loadSpreadsheet- "+r+"]"+sheet.getCell(r, 1).value);
            
        }
        let advArr: ITykoRows[] = [];
        for (let r = 2; r < lastColumn; r++) {
            const data = sheet.getCell(r, 8).value; //номер колонки
            if (data === null ) continue;
            advArr.push({
                rowNumber: data,
                rowText_Lang: [sheet.getCell(r, 9).value, sheet.getCell(r, 11).value], 
                rowType: sheet.getCell(r, 10).value,
            });
            //console.log("gs loadSpreadsheet- "+r+"]"+sheet.getCell(r, 1).value);
            
        }
        
        ret = {
            url: sheet.getCell(2, 1).value,
            tableName: sheet.getCell(5, 1).value,
            rowlist: arr,
            advRowlist: advArr,
        };
        return ret;

    } catch (e) {
        console.error('Error LoadDocInfo: ', e);
        return ret;
    }
}

export const apiLoadTykoTableData = async (tableIndex:string, sheetIndex:string, infoData:ITykoTableInfo): Promise<ITykoTableData[] | []> => {
    let resArr: ITykoTableData[] = [];

    const getMaxTykoRows = (list:ITykoRows[]) => {
        let maxRow: string = 'A';
        //console.log("==0="+list.length);
        list.forEach((item) => {
            //console.log("=======1="+item.rowNumber);
            if (item.rowNumber > maxRow) {
                maxRow = item.rowNumber;
            }
        })
        return maxRow;
    };

    try {
        const doc = new GoogleSpreadsheet(tableIndex);

        console.log("gs loadSpreadsheet");
        await doc.useServiceAccountAuth(creds);
        // loads document properties and worksheets
        await doc.loadInfo();
        const sheet = doc.sheetsById[sheetIndex];

        let lastRow = sheet.rowCount/1;
        console.log("lastRow "+lastRow);
        const maxRow = getMaxTykoRows([...infoData.rowlist, ...infoData.advRowlist]);
        console.log("gs apiLoadTykoTableData maxRow="+maxRow);
        await sheet.loadCells('A1:'+maxRow+lastRow); // loads a range of cells
        console.log(sheet.cellStats); // total cells, loaded, how many <non-empty></non-empty>
        //const rows = await sheet.getRows();

        const getAlfabet = (letter:string) => {
            return letter.charCodeAt(0) - 65;
        }

        
        for (let r = 1; r < lastRow; r++) {
            const data = sheet.getCell(r, 0).value; //Ваш Ротарі Клуб, 
            if (data === null ) continue;

            let color = "white";
            const rowArr:string[] = infoData.rowlist.map((item) => {
                
                const cellNumberRow:number = getAlfabet(item.rowNumber);
                const cellData = item.rowType === "date" ? sheet.getCell(r, cellNumberRow).formattedValue : sheet.getCell(r, cellNumberRow).value;
                
                
                //console.log("gs tykoRows.map l="+item.rowNumber+", row="+cellNumberRow+", data="+cellData);
                //if (item.rowType !== "date" && sheet.getCell(r, cellNumberRow).value !== null )

                if (item.rowType === "color") 
                    color = sheet.getCell(r, cellNumberRow).value;
                else
                    return cellData;
            })

            let haveFile:boolean = false;
            let email:string = '';

            let isSendedEmail:boolean = false;
            let status: string = '';
            let user_name: string = '';
            let short_desc: string = '';
            let email_comment: string = '';
            let subcommittee: string = '';
            let date: string = '';
            let rating: number = 0;
            const rowAdvArr:string[] = infoData.advRowlist.map((item) => {
                
                const cellNumberRow:number = getAlfabet(item.rowNumber);
                const cellData = item.rowType === "date" ? sheet.getCell(r, cellNumberRow).formattedValue : sheet.getCell(r, cellNumberRow).value;
                
                //console.log("========== gs tykoRows.map l="+item.rowNumber+", row="+cellNumberRow+", type="+item.rowType+", data="+cellData);
                //if (item.rowType !== "date" && sheet.getCell(r, cellNumberRow).value !== null )
                const strData:string = cellData;
                if (item.rowType === "file" && strData && strData.startsWith('http') ) 
                    haveFile = true;
                if (item.rowType === "email" ) 
                    email = sheet.getCell(r, cellNumberRow).value;

                if (item.rowType === "sendEmail" && sheet.getCell(r, cellNumberRow).value === 'sended') 
                    isSendedEmail = true;
                if (item.rowType === "status" ) 
                    status = sheet.getCell(r, cellNumberRow).value;
                if (item.rowType === "user_name" ) 
                    user_name = sheet.getCell(r, cellNumberRow).value;
                if (item.rowType === "short_desc" ) 
                    short_desc = sheet.getCell(r, cellNumberRow).value;
                if (item.rowType === "email_comment" ) 
                    email_comment = sheet.getCell(r, cellNumberRow).value;
                if (item.rowType === "subcommittee" ) 
                    subcommittee = sheet.getCell(r, cellNumberRow).value;
                if (item.rowType === "date" ) 
                    date = sheet.getCell(r, cellNumberRow).formattedValue;
                if (item.rowType === "rating" ) 
                    rating = sheet.getCell(r, cellNumberRow).value;

                if (item.rowType === "color") 
                    color = sheet.getCell(r, cellNumberRow).value;
                else
                    return cellData;
            })

            const tykoData: ITykoTableData = {
                id: r+1,
                color: color,
                email: email,
                haveFile: haveFile,
                data: rowArr,
                advData: rowAdvArr,
                rowlist: infoData.rowlist,
                advRowlist: infoData.advRowlist,

                sendEmail: isSendedEmail,
                status,
                user_name,
                short_desc,
                email_comment,
                subcommittee,
                date,
                rating,
            };
            ///let resArr2: string[][] = [];
            //const rowArr:string[] = ["1","2","3"];
            resArr.push(tykoData);
            //console.log("gs loadSpreadsheet- "+r+"]"+sheet.getCell(r, 1).value);*/
        }
        
        return resArr;
    } catch (e) {
        console.error('Error LoadDocInfo: ', e);
        return resArr;
    }
}

export const apiLoadTykoTablesInfoList= async (): Promise<ITykoTableListInfo[]> => {

    try {
        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        console.log("gs apiLoadTykoTablesInfoList");
        await doc.useServiceAccountAuth(creds);
        // loads document properties and worksheets
        await doc.loadInfo();
        const sheet = doc.sheetsById[SHEET_ID];

        let lastRow = sheet.rowCount/1;
        console.log("lastRow "+lastRow);
        await sheet.loadCells('A1:L'+lastRow); // loads a range of cells
        console.log(sheet.cellStats); // total cells, loaded, how many <non-empty></non-empty>
        //const rows = await sheet.getRows();

        let arr: ITykoTableListInfo[] = [];
        for (let r = 2; r < lastRow; r++) {
            const data = sheet.getCell(r, 1).value; //Ваш Ротарі Клуб, 
            if (data === null ) continue;
            const ukr: IMenuTitles = {
                title: sheet.getCell(r, 4).value,
                desc: sheet.getCell(r, 5).value,
            };
            const eng: IMenuTitles = {
                title: sheet.getCell(r, 6).value,
                desc: sheet.getCell(r, 7).value,
            };
            const langArr:IMenuTitles[] = [ukr,eng];
            arr.push({
                tableId: data,
                tableIndex: sheet.getCell(r, 2).value,
                sheetIndex: sheet.getCell(r, 3).value,
                menuTitles: langArr,
                group: sheet.getCell(r, 9).value,
                formLink: sheet.getCell(r, 10).value,
                readAccess: sheet.getCell(r, 11).value,
            });
            console.log("gs apiLoadTykoTablesList- row="+r+"]"+sheet.getCell(r, 4).value);
        }
        console.log("gs apiLoadTykoTablesList arrsize="+arr.length+"]");
        return arr; 
        
    } catch (e) {
        console.error('Error LoadDocInfo: ', e);
        return [];
    }
}
/*
export const apiLoadFilterList= async (): Promise<IStatusFilter[] | []> => {
    const filterList:IStatusFilterColumn[] = [ 
        {
            tableId: "civil",
            column: "O",
            title: "civil title",
            filters: [
                {status: "approved", color: "PaleGreen", cheked: false, },
                {status: "in progress", color: "lightyellow", cheked: true, },
                {status: "deleted", color: "lightgray", cheked: false, },
                {status: "not processed", color: "", cheked: true, },
            ]
        },
        {
            tableId: "med",
            column: "P",
            title: "civil title",
            filters: [
                {status: "approved", color: "PaleGreen", cheked: false, },
                {status: "in progress", color: "lightyellow", cheked: true, },
                {status: "deleted", color: "lightgray", cheked: false, },
                {status: "not processed", color: "", cheked: true, },
            ]
        },
        {
            tableId: "winter",
            column: "M",
            title: "civil title",
            filters: [
                {status: "approved", color: "PaleGreen", cheked: false, },
                {status: "in progress", color: "lightyellow", cheked: true, },
                {status: "deleted", color: "lightgray", cheked: false, },
                {status: "not processed", color: "", cheked: true, },
            ]
        }
    ];
    return filterList;
}*/
