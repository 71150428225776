import React, {useState} from 'react';
import moment from 'moment';

import TableContainer from '@mui/material/TableContainer';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { pink } from '@mui/material/colors';
import {useLocalStorage} from "../useLocalStorage";

import {apiLoadTykoTablesInfoList, apiLoadTykoTableInfoData, apiLoadTykoTableData, ITykoTableListInfo, ITykoTableInfo, ITykoTableData, ITykoRows} from './api/apiTykoTables';
import GSTykoTable from './GSTykoTable';
import TykoTableDetailsPage from './TykoTableDetailsPage';
import TTHeader from './TTHeader'
import { async } from 'q';
import GoogleLoginButton from '../GoogleLogin/GoogleLoginButton';
import { IUserProfile } from '../api/apiAdministration';
import {sendEmail} from './api/sendEmail';
import ColoredCheckbocksFilter from '../GuiComponents/ColoredCheckbocksFilter';

const languageArr = ["Ang", "Укр"];
const defaultLangId = 1;//Ang

export default function TykoTablePage({needEmailFilter, sheetId, isDebug, needTranslate}:{
            needEmailFilter:boolean, 
            sheetId:string, //table ID from browser arg
            isDebug:boolean, 
            needTranslate:boolean}) {    
    const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);
    const [langIdSaved, langIdSavedSet] = useLocalStorage<number>('langId', 1);
    const [arrCheckboxStatus, arrCheckboxStatusSet] = useLocalStorage<boolean[] | []>('arrCheckboxStatus', []);

    const [tableMeta, tableMetaSet] = useState<ITykoTableListInfo[] | []>([]);
    const [tableInfo, tableInfoSet] = useState<ITykoTableListInfo | null>(null);
    const [tableInfoData, tableInfoDataSet] = useState<ITykoTableInfo | null>(null);
    //const [tableData, tableDataSet] = useState<ITykoTableData[] | []>([]);
    const [mergedData, mergedDataSet] = useState<ITykoTableData[] | []>([]);
    const [filteredTableData, filteredTableDataSet] = useState<ITykoTableData[] | []>([]);
    const [openDetailsDialogIndex, openDetailsDialogIndexSet] = React.useState(-1);
    const [langId, langIdSet] = useState<number>(languageArr && languageArr.length > langIdSaved ? langIdSaved : defaultLangId);
    const [sheetId2, sheetId2Set] = useState<string>("");
    const [pagesGroup, pagesGroupSet] = useState<string[]>([]);
    const [checkedGray, setCheckedGray] = useState<boolean>(arrCheckboxStatus && arrCheckboxStatus.length >= 2 ? arrCheckboxStatus[0] : true);
    const [checkedGreen, setCheckedGreen] = useState<boolean>(arrCheckboxStatus && arrCheckboxStatus.length >= 2 ? arrCheckboxStatus[1] : true);
    

    const fetchMyAPI = React.useCallback(async () => {
        const metaList: ITykoTableListInfo[] = await apiLoadTykoTablesInfoList();
        console.log("gs loadMetaData arrsize="+metaList.length+"]");
        tableMetaSet(metaList);

        const correctedSheetIdent = initSheetId(metaList);

        if (metaList)
            loadMergedData(metaList, correctedSheetIdent);
    }, []);

    const loadMergedData = async (list:ITykoTableListInfo[], correctedSheetIdent:string) => {  
        //clear data
        console.log("============= loadMergedData");
        mergedDataSet([]);
        
        const filteredList: ITykoTableListInfo[] = filterGroupeList(list);
        console.log("=============2 filteredList count="+filteredList.length);
        
        const pages_:string[] = getSheetList(filteredList);
        pagesGroupSet(pages_);
        
        //const info: ITykoTableListInfo | null = getITykoTableInfoFromList(filteredList, correctedSheetIdent);
        //tableInfoSet(info);
        //console.log("============= info="+(info?"true2":"false2"));

        //load each table from merged tables
        const dataPromises = pages_.map(async (item) => {
            const isRowTitle = correctedSheetIdent === item;
            return await loadData(filteredList, list, item, isRowTitle);
        });
    
        const allData: ITykoTableData[][] = await Promise.all(dataPromises);
        let newMergedData: ITykoTableData[] = allData.flat();

        mergedDataSet(newMergedData);
    }

    const loadData = async (filteredList: ITykoTableListInfo[], list:ITykoTableListInfo[], correctedSheetIdent:string, isRowTitle:boolean) => {  
        const info: ITykoTableListInfo | null = getITykoTableInfoFromList(filteredList, correctedSheetIdent);
        if(isRowTitle)
            tableInfoSet(info);
        if (info) {
            const info2: ITykoTableListInfo = info;
            const data: ITykoTableInfo | null = await apiLoadTykoTableInfoData(correctedSheetIdent);

            if(isRowTitle)
                tableInfoDataSet(data);

            if (data) {
                const tykoData = await apiLoadTykoTableData(info2.tableIndex, info2.sheetIndex, data);
                console.log("====== loadData size="+tykoData.length+", from table="+correctedSheetIdent);

                if (sheetId!=="disaster")
                    tykoData.reverse();

                //tableDataSet(tykoData);
                //mergedDataSet(prevState => [...prevState, ...tykoData]);
                
                
                console.log("============= email="+userProfile?.email);
                //filteredTableDataSet(filterTableData(userProfile?.email, checkedGray, checkedGreen, tykoData));
                return tykoData;
            }
        }
        return [];
    }

    const filterTableData = (email:string|undefined, chGray:boolean, chGreen:boolean, data:ITykoTableData[]) => {
        // Функція для аналізу рядка дати
        if (sheetId==='coordcom' || sheetId==='coordcom_test') {
            data.sort((a, b) => {
                const [dayA, monthA, yearWithTimeA] = a.date.split('.');
                const [yearA, timeA] = yearWithTimeA.split(' ');
                const [hoursA, minutesA, secondsA] = timeA.split(':');
            
                const [dayB, monthB, yearWithTimeB] = b.date.split('.');
                const [yearB, timeB] = yearWithTimeB.split(' ');
                const [hoursB, minutesB, secondsB] = timeB.split(':');
            
                const dateA = new Date(+yearA, +monthA - 1, +dayA, +hoursA, +minutesA, +secondsA);
                console.log("strA="+a.date+"dateA="+dateA); // це має вивести правильну дату, якщо формат є вірним
                const dateB = new Date(+yearB, +monthB - 1, +dayB, +hoursB, +minutesB, +secondsB);
                console.log("strB="+b.date+"dateB="+dateB); // це має вивести правильну дату, якщо формат є вірним
            
                return dateB.getTime() - dateA.getTime();
        })}  

        data.forEach((item, index) => {
            item.id = data.length - index + 1;
        });


        const newdata:ITykoTableData[] = [];
        data.map((item) => {
            if ( ( (chGray && item.color === 'lightgray') ||
            (chGreen && (item.color === 'PaleGreen' || item.color === 'LightBlue')) || 
            (item.color !== 'lightgray' && item.color !== 'PaleGreen' && item.color !== 'LightBlue') ) &&
            (!needEmailFilter || (needEmailFilter && email === item.email))
            )
                newdata.push(item);
            //console.log('======= filterTableData uemail='+email+', email='+item.email);
        })

        newdata.map((item) => {
            console.log("=== befor date="+item.date+", email="+item.email)
        })
        
        /*newdata.forEach((item, index) => {
            item.id = index + 1;  // Якщо ви хочете починати з 1
        });

        newdata.forEach((item, index) => {
            item.id = newdata.length - index + 1;
        });*/

        return newdata;
    }
    const clearData = async () => {
        //tableInfoSet(null);
        tableInfoDataSet(null);
        //tableDataSet([]);
        mergedDataSet([]);
    }
    
    React.useEffect(() => {
        filteredTableDataSet(filterTableData(userProfile?.email, checkedGray,checkedGreen, mergedData))
    }, [userProfile?.email, checkedGray, checkedGreen, mergedData]);

    React.useEffect(() => {
        fetchMyAPI();
    }, [fetchMyAPI]);

    const initSheetId = (list:ITykoTableListInfo[]) => {
        let correctedSheetIdent:string = "";

        const groupList:string[] = getGroupList(list);
        if (groupList.includes(sheetId)) {
            const reversed = [...list].reverse();
            reversed.map((item) => {
                if (item.group === sheetId) {
                    correctedSheetIdent = item.tableId;
                    console.log("============= initSheetId(group) sheetId="+sheetId+", correctedSheetIdent="+correctedSheetIdent);
                }
            })
        }
        else { //single sheet
            const reversed = [...list].reverse();
            reversed.map((item) => {
                if (item.tableId === sheetId) {
                    correctedSheetIdent = sheetId;
                    console.log("============= initSheetId(single) sheetId="+sheetId+", correctedSheetIdent="+correctedSheetIdent);
                }
            })
        }
        
        return correctedSheetIdent;
    }
    
    const filterGroupeList = (list:ITykoTableListInfo[]) => {
        const strArr:ITykoTableListInfo[] = [];
        const groupList:string[] = getGroupList(list);
        //console.log("============= groupList size="+groupList.length);
        //console.log("======== groupList 1="+groupList[0]);
        //console.log("======== groupList s="+sheetId);
        if (groupList.includes(sheetId)) {
            list.map((item) => {
                if (item.group === sheetId) {
                    strArr.push(item);
                    console.log("============= filterGroupeList(group) item="+item.tableId+", sheetId2="+sheetId2);
                }
            })
        }
        else { //single sheet
            list.map((item) => {
                if (item.tableId === sheetId) {
                    strArr.push(item);
                    //sheetId2Set(sheetId);
                    console.log("============= filterGroupeList2(single) item="+item.tableId+", sheetId2="+sheetId2);
                }
            })
        }
        
        return strArr;
    };
    const getGroupList = (list:ITykoTableListInfo[]) => {
        //console.log("============= getGroupList");
        const strArr:string[] = [];
        list.map((item) => {
            if (!strArr.includes(item.group)) {
                strArr.push(item.group);
                //console.log("============= getGroupList item="+item.group);
            }
        })
        return strArr;
    };
    const getSheetList = (list:ITykoTableListInfo[]) => {
        console.log("============= getSheetList");
        const strArr:string[] = [];
        list.map((item) => {
            //if (!strArr.includes(item.group)) {
                strArr.push(item.tableId);
                console.log("============= getSheetList item="+item.tableId);
            //}
        })
        return strArr;
    };

    const getITykoTableInfoFromList = (list:ITykoTableListInfo[] | [], id:string) => {
        let sheetInfo: ITykoTableListInfo | null = null;
        console.log("getITykoTableInfoFromList size="+list.length);
        list.forEach((item) => {
            console.log("getITykoTableInfoFromList item.tableId="+item.tableId+", seachid="+id);
            if (item.tableId === id) {
                sheetInfo = item;
            }
        })
        return sheetInfo;
    };

    const colTitleList = (list:ITykoRows[]) => {
        const strArr:string[] = [];
        list.map((item) => {
            strArr.push(item.rowText_Lang[langId]);
        })
        return strArr;
    };

    const handleDetilsDialogClose = () => {
        openDetailsDialogIndexSet(-1);
    };

    const rowTableClicked = (index: number) => {
        openDetailsDialogIndexSet(index);
    }

    const moreTableTestClicked = (index: number, item: ITykoTableData) => {
        sendTableEmail(index, item, true);
    }

    const moreTableClicked = (index: number, item: ITykoTableData) => {
        sendTableEmail(index, item, false);
    }

    const sendTableEmail = (index: number, item: ITykoTableData, isTested: boolean) => {
        const id:string = item.id.toString();
        //const send_to = "o.kosmyna.rotary@gmail.com";
        const user_name = item.user_name;
        const status = item.status;
        const short_desc = item.short_desc;
        const isAprove = status?.startsWith('затверджено');

        let adv_message = item.email_comment;
        adv_message += item.subcommittee==='логістика'?'\n Голова логістичного підкомітету: Борис Боднар':'';
        adv_message += item.subcommittee==='спецтехніка'?'\n Голова підкомітету спецтехники Володимир Бондаренко':'';
        adv_message += item.subcommittee==='медичний'?'\n Голова медичного підкомітету: Оля Палійчук':'';
        adv_message += item.subcommittee==='техдопомога'?'\n Голова підкомітету Техдопомога: Олег Косьмина':'';
        adv_message += isAprove?'\n\n За подальшими роз`ясненнями, стосовно договорів та фінансових питань, звертатися до голови фінансового підкомітету: Ігоря Яковчука, finkd2232@ukr.net.':'';

        let send_to:string = '';
        
        if (!isTested) {
            send_to += item.email+', ';
            send_to += 'leskovit@gmail.com, ';
            send_to += item.subcommittee==='логістика'?'borysbodnar@gmail.com,':'';
            send_to += item.subcommittee==='спецтехніка'?'volbondarenko@gmail.com,':'';
            send_to += item.subcommittee==='медичний'?'oncology@2upost.com,':'';
            send_to += isAprove?'finkd2232@ukr.net,':'';
        }

        if (!isTested) {
            alert("Лист відправлено. Перевірте вашу пошту: "+send_to);
            sendEmail(id, send_to, user_name, status, short_desc, adv_message);
        }
        else {
            alert("Лист відправлено тестово. Перевірте вашу пошту: "+send_to);
            sendEmail(id, send_to, user_name, status, short_desc, adv_message);
        }
        
        //needUpdateData();
    }

    const onSwitchLanguage = () => {
        console.log("swtch lang to "+languageArr[langId]);
        langIdSet(langId===0 ? 1 : 0);
        langIdSavedSet(langId===0 ? 1 : 0);
    }

    const onSwitchPages = (page:string) => {
        if(page === sheetId2) 
            console.log("swtch ignore. curent page = "+sheetId2);
        else
        {
            console.log("swtch page to "+page);
            clearData();
            sheetId2Set(page);
            loadMergedData(tableMeta, page);
        }
    }

    const handleChangeGray = () => {
        setCheckedGray(!checkedGray);
        arrCheckboxStatusSet([!checkedGray, checkedGreen]);
        console.log("swtch cheked gray to "+!checkedGray);
        if (mergedData) {
            filteredTableDataSet(filterTableData(userProfile?.email, !checkedGray,checkedGreen, mergedData))
        }
    };
      
    const handleChangeGreen = () => {
        setCheckedGreen(!checkedGreen);
        arrCheckboxStatusSet([checkedGray, !checkedGreen]);
        console.log("swtch cheked green to "+!checkedGreen);
        if (mergedData) {
            filteredTableDataSet(filterTableData(userProfile?.email, checkedGray,!checkedGreen, mergedData))
        }
    };
    const onLoginSuccess = (userData:IUserProfile) => {
        setUserProfile(userData);
        console.log("====== onLoginSuccess="+userData?.email);
        //fetchMyAPI();
        if (mergedData) {
            //filteredTableDataSet(filterTableData(userData?.email, checkedGray,!checkedGreen, mergedData))
        }
    }
    const onLogoutSuccess = () => {
        setUserProfile(null);
        if (mergedData) {
            ///filteredTableDataSet(filterTableData('', checkedGray,!checkedGreen, mergedData))
        }
    }

    return (
        <>
            <TableContainer component={Paper}>
                <TTHeader 
                    title={tableInfo?.menuTitles[langId].title} 
                    userProfile={userProfile}
                    language={languageArr[langId]} 
                    onSwitchLanguage={onSwitchLanguage} 
                    pages={pagesGroup}
                    onSwitchPages={onSwitchPages}
                    onLoginSuccess={onLoginSuccess}
                    onLogoutSuccess={onLogoutSuccess}
                    needEmailFilter={needEmailFilter}
                    needTranslate={needTranslate}
                />
                <Typography variant="h5" gutterBottom>{tableInfo?.menuTitles[langId].desc}</Typography>
                <br/>

                {!needEmailFilter || userProfile ?
                <Box sx={{ flexGrow: 1 }}>
                    <ColoredCheckbocksFilter 
                        checkedGray={checkedGray} 
                        handleChangeGray={handleChangeGray}
                        checkedGreen={checkedGreen}
                        handleChangeGreen={handleChangeGreen}
                        langId={langId}
                    />

                    <Grid container spacing={2}>
                        <Grid item xs>
                        <div>
                        {filteredTableData && tableInfoData ?
                            filteredTableData.length ?
                            <>{needEmailFilter ? 
                                <Grid container direction="column" alignItems="center" justifyContent="center">
                                    <Typography variant="h6">Всі запити, що зареєстровані на {userProfile?.email}
                                </Typography> </Grid>
                                : null}
                            <GSTykoTable 
                                arr={filteredTableData} 
                                colList={colTitleList(tableInfoData.rowlist)} 
                                rowClicked={rowTableClicked} 
                                moreTestClicked={moreTableTestClicked}
                                moreClicked={moreTableClicked}
                                adminProfile={isDebug}
                            />
                            </>
                            :   <Grid 
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ minHeight: '100vh' }}
                                >
                                    <Box sx={{ flexGrow: 1 }}>
                                    <Typography variant="h6" gutterBottom>Ви не маєте зареєстрованих запитів на "{userProfile?.email}".</Typography>
                                    </Box>
                                </Grid>
                            :
                            <Box sx={{ width: '40px', height: '40px', }}>
                                <CircularProgress />
                            </Box>
                        }
                        </div>
                        </Grid>
                    </Grid>
                </Box>
                :
                <Grid 
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6" gutterBottom>Вам потрібно залогінитись (за допомогою кнопки нижче)</Typography>
                    <GoogleLoginButton onLoginSuccess={onLoginSuccess} />
                    </Box>
                </Grid>
                }

                {tableInfoData ? 
                <TykoTableDetailsPage 
                    arrItem={filteredTableData && openDetailsDialogIndex>=0? filteredTableData[openDetailsDialogIndex].advData : []} 
                    colList={filteredTableData && openDetailsDialogIndex>=0? colTitleList(filteredTableData[openDetailsDialogIndex].advRowlist) : []}
                    //colList={colTitleList(tableInfoData.advRowlist)}
                    open={openDetailsDialogIndex}
                    onClose={handleDetilsDialogClose}
                /> : null}
            </TableContainer>
        </>
    );
}
