import React, {useState} from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';

import {apiTykoTableAddRow, ITykoFormRow, ITykoFormStep, ITykoFormItem} from './api/apiTykoTableAddRow';


export default function TykoFormStep({step, id, onChange}:{step:ITykoFormStep, id: number, onChange: (value:any, itemId:number, stepId:number) => void }) {
    //const [stepId, setStepId] = React.useState(id);
    const stepId = id;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, itemId:number) => {
        //step.arr[id].data = event.target.value;
        console.log("====== handleChange v="+event.target.value);
        onChange(event.target.value, itemId, stepId);
    }

    const top100Films = [
        { label: 'RC Poltava', year: 1972 },
        { label: 'RC Kharkiv', year: 1974 },
        { label: 'RC Lviv', year: 2008 },
        { label: 'RC Mykolaiv', year: 1957 },
        { label: "RC Dnipro", year: 1993 },
        { label: 'RC Zaporizhzhia-Khortytsia', year: 1994 },
        ]

    return (
        <Box sx={{ width: '100%', p: '20px' }}>
        
        {step.arr.map((item,index) => (
            <>
            <Typography>{item.rowName}{item.required ? ' *' : false}</Typography>
            {item.type === 'clublist' ? 
                <Autocomplete
                 disablePortal
                 id="combo-box-demo"
                 fullWidth
                 options={top100Films}
                 sx={{ width: 300 }}
                 renderInput={(params) => 
                 <TextField {...params} fullWidth label="RC Club" />}
               />
            :
                <TextField 
                    key={item.rowName}
                    id={item.rowName} 
                    value={item.data}
                    required 
                    type={item.type} 
                    fullWidth 
                    //defaultValue={item.data?item.data:"-"}
                    variant="outlined" 
                    onChange={(e) => handleChange(e, index)}
                />
            }
            </>
        ))
        }
        {//JSON.stringify(step.arr)
        }
        </Box>
    )
};
