import React, {useEffect, useState} from 'react';

export function useLocalStorage<T>(keyName: string, defaultValue?: T) {
    const [storedValue, setStoredValue] = React.useState(() => {
      try {
        const value = window.localStorage.getItem(keyName);
        //console.log("============== get Storage "+value);//+ value ? value.length : -1);
        if (value) {
          return JSON.parse(value);
        } else {
          window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
          //console.log("============== save default Storage "+defaultValue);
          return defaultValue;
        }
      } catch (err) {
        return defaultValue;
      }
    });
  
    function setValue(newValue: T) {
      try {
        window.localStorage.setItem(keyName, JSON.stringify(newValue));
        //console.log("============== save Storage "+newValue);
      } catch (err) {}
      setStoredValue(newValue);
    };
  
    return [storedValue, setValue];
  };