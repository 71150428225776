import React, {useState} from 'react';
import Box,  { BoxProps } from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const chGrayLabel = ["Показати відхилені", "Show rejected"];
const chGreenLabel = ["Показати затверджені", "Show approved"];

export default function ColoredCheckbocksFilter(
    {checkedGray, handleChangeGray, checkedGreen, handleChangeGreen, langId}:
    { 
        checkedGray:boolean, 
        handleChangeGray: () => void,
        checkedGreen:boolean,
        handleChangeGreen: () => void, 
        langId:number
    }) { 

    return (
        <>
            <FormControlLabel 
                        control={<Checkbox
                        checked={checkedGray}
                        onChange={handleChangeGray}
                        inputProps={{ 'aria-label': 'controlled' }}
                        sx={{
                            color: 'lightgray',
                            '&.Mui-checked': {
                            color: 'lightgray',
                            },
                        }}
                    />} label={chGrayLabel[langId]} />
                    <FormControlLabel control={<Checkbox
                    checked={checkedGreen}
                    onChange={handleChangeGreen}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                        color: 'PaleGreen',
                        '&.Mui-checked': {
                        color: 'PaleGreen',
                        },
                    }}
                    />} label={chGreenLabel[langId]} />
        </>
    );
}

