import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// @ts-ignore
import GSTMedPage from "./pages/GSTMedPage"
import DevPage from "./pages/DevPage"
import RedirectPage from "./pages/RedirectPage"
import RedirectPage2 from "./pages/RedirectPage2"
import RedirectPage3 from "./pages/RedirectPage3"
import RedirectPage4 from "./pages/RedirectPage4"
import RedirectPage5 from "./pages/RedirectPage5"
import CoordComTablePage from "./pages/CoordComTablePage"
import TykoTables from './mycomponents/TykoTables/TykoTables';
import TykoTablesUser from './mycomponents/TykoTables/TykoTablesUser';
import TykoForms from './mycomponents/TykoTables/TykoForms';
import TestEmailPage from './mycomponents/TestEmailPage';
import MainPage from './mycomponents/GUID2232Projects/MainPage';
import D2232FormsPage from './mycomponents/GUID2232Projects/D2232FormsPage';
import D2232MyTables from './mycomponents/GUID2232Projects/D2232MyTables';
import D2232MyCoordCom from './mycomponents/GUID2232Projects/D2232MyCoordCom';
import TechaidPage from './mycomponents/GUID2232Projects/TechaidPage';

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
      <Routes>
        <Route path="/peremoga" element={<DevPage />} />
        <Route path="/peremogabd1" element={<GSTMedPage />} />
        <Route path="/peremogabd2" element={<GSTMedPage />} />
        <Route path="/peremogabd3" element={<GSTMedPage />} />
        <Route path="/coordcom" element={<CoordComTablePage />} />

        <Route path="/tykotable" element={<TykoTables />} />
        <Route path="/tyko-table" element={<TykoTablesUser />} />
        <Route path="/tykoform" element={<TykoForms />} />

        <Route path="/rczhhumaidqr" element={<RedirectPage />} />
        <Route path="/winterqr" element={<RedirectPage2 />} />
        <Route path="/aidrequest" element={<RedirectPage3 />} />
        <Route path="/generatorreq" element={<RedirectPage4 />} />
        <Route path="/generatorinfo" element={<RedirectPage5 />} />

        <Route path="/d2232projects" element={<MainPage />} />
        <Route path="/d2232forms" element={<D2232FormsPage />} />
        <Route path="/myforms" element={<D2232FormsPage />} />
        <Route path="/myrequests" element={<D2232MyTables />} />
        <Route path="/myCoordCom" element={<D2232MyCoordCom />} />

        <Route path="/testemail" element={<TestEmailPage />} />
        <Route path="/techaidpage" element={<TechaidPage />} />

        <Route path="/" element={<Navigate replace to="/peremoga" />} />
      </Routes>

      </BrowserRouter>  
    </React.StrictMode>
  );
}

export default App;
