import React, {useState} from 'react';
import { useSearchParams } from "react-router-dom";

import TykoTablePage from './TykoTablePage';

export default function TykoTables() {
    const [sheetList, sheetListSet] = useState<string[] | []>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const tableName = searchParams.get('n');
    const isDebug:boolean = searchParams.get('debug') === "true";

    return (
        <div>
            {sheetList && tableName ? 
            <TykoTablePage needEmailFilter={false} sheetId={tableName} isDebug={isDebug} needTranslate={true}/> : "error: formName1 null"}
        </div>
    );
}
