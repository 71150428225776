import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import IconTable from "../../assets/img/icons2/tables.png";
import IconMed from "../../assets/img/icons3/med.png";
import IconWinter from "../../assets/img/icons3/winter.png";
import IconLog from "../../assets/img/icons3/log.png";
import IconSpec from "../../assets/img/icons3/spec.png";
import IconWash from "../../assets/img/icons3/water.png";
import IconComm from "../../assets/img/icons3/comm3.png";
import IconPeople from "../../assets/img/icons3/people.png";


export default function LeftMenuPanel({iconFirst, onClickButton, isVertical}:{iconFirst:string, onClickButton:(nameButton:string)=>void, isVertical:boolean}) {
    const direction = isVertical ? 'column' : 'row';
    const heightItem = isVertical ? '50px' : '30px';

    return (
        <Box  display="flex" flexDirection={direction} sx={{bgcolor: 'gray', mt: '4px'}} >
                    <Stack height="100%" direction={direction} spacing={0}>

                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => onClickButton('forms')} >
                            <Tooltip title="Запити">
                                <img height={heightItem} src={iconFirst} />
                            </Tooltip>
                        </IconButton>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => onClickButton('fmed')} >
                            <Tooltip title="Медичний">
                                <img height={heightItem} src={IconMed} />
                            </Tooltip>
                        </IconButton>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => onClickButton('flog')} >
                            <Tooltip title="Логістичний">
                                <img height={heightItem} src={IconLog} />
                            </Tooltip>
                        </IconButton>
                        {/*<IconButton color="secondary" aria-label="add an alarm" onClick={() => onClickButton('ftech')} >
                            <Tooltip title="Техдопомога(Зима)">
                                <img height={heightItem} src={IconWinter} />
                            </Tooltip>
                        </IconButton>*/}
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => onClickButton('fspec')} >
                            <Tooltip title="Спецтехніка">
                                <img height={heightItem} src={IconSpec} />
                            </Tooltip>
                        </IconButton>
                        {/*<IconButton color="secondary" aria-label="add an alarm" onClick={() => onClickButton('fwash')} >
                            <Tooltip title="Wash">
                                <img height={heightItem} src={IconWash} />
                            </Tooltip>
                        </IconButton>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => onClickButton('fpeople')} >
                            <Tooltip title="Люди">
                                <img height={heightItem} src={IconPeople} />
                            </Tooltip>
                        </IconButton>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => onClickButton('fcoordcom')} >
                            <Tooltip title="Інше...">
                                <img height={heightItem} src={IconComm} />
                            </Tooltip>
                        </IconButton>*/}
                    </Stack>
                </Box>
    );
}
