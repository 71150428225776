// @ts-ignore
import {GoogleSpreadsheet} from "google-spreadsheet";
import creds from "../../../api/google-spreadsheet/med_credentials.json";

import {ITykoRows, SPREADSHEET_ID} from './apiTykoTables';

//const SPREADSHEET_ID = "1JT1lxAWy6JUFAacLEQ7vUCVNiKQ8XoEhW6QJ2ehBibo";
const SHEET_ID = "0";//DBTable

export interface ITykoFormMeta {
    rowlist: ITykoRows[],
};

export interface ITykoFormItem {
    rowName: string,
    data: any,
    type: string,//editbox, editfield, file_button, email, color, date, // clublist
    required: boolean,
};

export interface ITykoFormStep {
    title: string,
    arr: ITykoFormItem[],
};

export interface ITykoFormRow {
    arr: ITykoFormStep[],
};

const getFormatedData = (date:Date) => {
    var day = date.getDate() + "";
    var month = (date.getMonth() + 1) + "";
    var year = date.getFullYear() + "";
    var hour = date.getHours() + "";
    var minutes = date.getMinutes() + "";
    var seconds = date.getSeconds() + "";

    return day + "." + month + "." + year + " " + hour + ":" + minutes + ":" + seconds;
}

export const apiTykoTableAddRow = async (newRow:ITykoFormRow) => {
    const TT_ID = "12Jkyl0-5GeF08Ve2GDwRygj2IqJl1dxxznOopV9EiVs";
    const SH_ID = "1594562882";

    try {
        const doc = new GoogleSpreadsheet(TT_ID);

        console.log("gs apiTykoTableAddRow");
        await doc.useServiceAccountAuth(creds);
        // loads document properties and worksheets
        await doc.loadInfo();
        const sheet = doc.sheetsById[SH_ID];

        let resOj = {};
        Object.assign(resOj, {['Позначка часу']: getFormatedData(new Date()) });

        newRow.arr.map((item) => {
            const step1:ITykoFormStep = item;
            step1.arr.map(({rowName, data}) => {
                console.log("====== step1.arr.map rowName="+rowName+", data="+data);
                const newPropsObj = {
                    [rowName]:data,
                };
                Object.assign(resOj, newPropsObj);
            });
        })

        console.log("====== apiTykoTableAddRow obj="+JSON.stringify(resOj));
        const moreRows = await sheet.addRows([resOj]);
        //const moreRows = await sheet.addRows([
        //    { name: 'Sergey Brin', 'Електронна адреса': 'sergey@google.com' },
        //    { name: 'Eric Schmidt', 'Електронна адреса': 'eric@google.com' },
        //  ]);
        
        
        
    } catch (e) {
        console.error('Error LoadDocInfo: ', e);
        
    }
}

export const apiLoadTykoFormMeta = async (sheetName:string): Promise<ITykoFormMeta | null> => {

    let ret: ITykoFormMeta | null = null;
    try {
        const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

        console.log("gs apiLoadTykoFormMeta");
        await doc.useServiceAccountAuth(creds);
        // loads document properties and worksheets
        await doc.loadInfo();
        const sheet = doc.sheetsByTitle[sheetName];

        let lastRow = 15;//sheet.rowCount/1;
        console.log("lastRow "+lastRow);
        await sheet.loadCells('A1:Q'+lastRow); // loads a range of cells
        console.log(sheet.cellStats); // total cells, loaded, how many <non-empty></non-empty>
        //const rows = await sheet.getRows();

        
        let arr: ITykoRows[] = [];
        for (let r = 2; r < lastRow; r++) {
            const data = sheet.getCell(r, 13).value; //номер колонки
            if (data === null ) continue;

            arr.push({
                rowNumber: data,
                rowText_Lang: [sheet.getCell(r, 14).value, sheet.getCell(r, 16).value], 
                rowType: sheet.getCell(r, 15).value,
            });
            console.log("============ r="+r+", rowText="+sheet.getCell(r, 14).value);
            
        }
        
        ret = {
            rowlist: arr,
        };
        return ret;

    } catch (e) {
        console.error('Error LoadDocInfo: ', e);
        return ret;
    }
}