import * as React from 'react';
import { useState } from 'react' 
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import GoogleLoginMenuItem from '../GoogleLogin/GoogleLoginMenuItem';
import GoogleLogoutButton from '../GoogleLogin/GoogleLogoutButton';
import { GoogleLogin, GoogleLogout, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'; 
import { IUserProfile } from '../api/apiAdministration';

export default function AccountMenu({userProfile, onLoginSuccess, onLogoutSuccess}:{userProfile: IUserProfile|null, onLoginSuccess: (userData: IUserProfile) => void, onLogoutSuccess: () => void}) {
  
  const loginSuccess = (userData: IUserProfile) => {
      onLoginSuccess(userData);
      //console.log("====== "+result?.email+", ")
  }
  const logoutSuccess = () => {
      onLogoutSuccess();
  }


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ bgcolor_: 'gray' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }} src={userProfile && userProfile.imgUrl ? userProfile.imgUrl : ''}>M</Avatar>
            
          </IconButton>
        </Tooltip>
      </Box>
      
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ pl: '20px', pb: '20px' }}>
          {userProfile?.email}
        </Box>
        
        <GoogleLoginMenuItem onLoginSuccess={loginSuccess} />
        <GoogleLogoutButton onLogoutSuccess={logoutSuccess} />
      </Menu>
    </React.Fragment>
  );
}