import React, { FunctionComponent, useState } from 'react' 
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Logout from '@mui/icons-material/Logout';

import { GoogleLogout } from 'react-google-login';

    //meta.name="google-signin-client_id";
    //meta.content="373258893453-2gsg7hdnaf4qno7bp8j749b1k6fejtcg.apps.googleusercontent.com";
    const GOOGLE_SIGN_CLIENT_ID = "373258893453-2gsg7hdnaf4qno7bp8j749b1k6fejtcg.apps.googleusercontent.com";


export default function GoogleLogoutButton({onLogoutSuccess}:{onLogoutSuccess: () => void}) {

    return ( 
        <GoogleLogout
            clientId={GOOGLE_SIGN_CLIENT_ID}
            render={renderProps => (
                <MenuItem onClick={renderProps.onClick}>
                    <ListItemIcon>
                    <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
              )}
            buttonText="Logout"
            onLogoutSuccess={onLogoutSuccess}
        >
        </GoogleLogout>
    ) 
}

