import React, { FunctionComponent, useState } from 'react' 
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import { getSuperUserProfile, IUserProfile } from '../api/apiAdministration';

import { GoogleLogin, GoogleLogout, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login'; 

export interface GoogleSignInComponentProps { 
    loginSuccess: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void; 
    } 

    //meta.name="google-signin-client_id";
    //meta.content="373258893453-2gsg7hdnaf4qno7bp8j749b1k6fejtcg.apps.googleusercontent.com";
    const GOOGLE_SIGN_CLIENT_ID = "373258893453-2gsg7hdnaf4qno7bp8j749b1k6fejtcg.apps.googleusercontent.com";


export default function GoogleLoginMenuItem({onLoginSuccess}:{onLoginSuccess: (userData: IUserProfile) => void}) {
    //const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);

    const loginSuccess = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        let result, token;
        if ("profileObj" in response) { result = response.profileObj; }
        if ("tokenId" in response) { token = response.tokenId; }
        
        const user:IUserProfile = {
            userName: result?.name,
            email: result?.email,
            imgUrl: result?.imageUrl,
            committee: getSuperUserProfile(result?.email),
        }
        //setUserProfile(user);
        onLoginSuccess(user);
        console.log("====== loginSuccess="+result?.email+", ")
    }

    return ( 
        <GoogleLogin 
            clientId={GOOGLE_SIGN_CLIENT_ID}
            render={renderProps => (
                <MenuItem onClick={renderProps.onClick}>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Change account
              </MenuItem>
              
              )}
            buttonText="Login"
            onSuccess={loginSuccess} 
            onFailure={(response: any) => { 
                //setLoginFailed(true); 
            }} 
            cookiePolicy={'single_host_origin'} 
            responseType='code,token' 
            isSignedIn={true}
            prompt="select_account"
        /> 
    ) 
}

